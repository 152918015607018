.getStarted-dilog-main-container {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.getStarted-dilog-cancle-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-right: 15px;
}
.getStarted-dialog-body-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.getStarted-dialog-header-text-image-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 140px;
  /* border: 0.5px solid gray; */
  margin-top: 1%;
}
@media (min-width: 800px) {
  .getStarted-dialog-header-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 35px;
  }
  .getStarted-dialog-header-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 35px;
  }
  .getStarted-dialog-header-image {
    width: 60%;
  }
  .getStarted-dialog-name-container {
    width: 100%;
    padding-left: 35px;
    flex-direction: column;
  }
  .getStarted-dialog-email-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 35px;
    flex-direction: column;
    align-items: flex-end;
  }
  .getStarted-dialog-phone-container {
    width: 40%;
    padding-left: 35px;
  }
  .getStarted-dialog-dmatAccount-container {
    width: 60%;
    padding-right: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .getStarted-dialog-message-container {
    margin-left: 35px;
    margin-right: 35px;
    height: 80px;
  }
  .getStarted-dialog-dmatAccount-selector {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .question-checkbox-continer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }
}
.getStarted-header-text-heading {
  font-size: 21px;
  font-weight: 400;
}
.getStarted-header-text-description {
  font-size: 11px;
  font-weight: 400;
  margin-top: 4%;
  color: rgb(110, 109, 109);
  line-height: 1.7;
}
.getStarted-dialog-textFields-container {
  width: 100%;
  /* border: 0.5px solid red; */
  flex-direction: column;
}
.getStarted-dialog-send-container {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  /* border: 0.5px solid lightgreen; */
}
.getStarted-dialog-name-email-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* border: 0.5px solid blue; */
}

.getStarted-dialog-phone-dmatAccount-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 3%;
}

.getStarted-dialog-dmatAccount-question {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  font-size: 16px;
  color: rgb(110, 109, 109);
  font-weight: 400;
}

.errorGetStartedContainers {
  width: 95%;
  color: red;
  font-size: 11px;
  height: 14px;
  font-weight: 500;
  margin-top: 5px;
}
.getStarted-dialog-thank-you-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.getStarted-dialog-check-icon {
  margin-top: 10%;
  height: 130px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffd118;
  border-radius: 100px;
  margin-bottom: 5%;
}
.getStarted-dialog-thank-you-text {
  font-size: 25px;
  font-weight: 400;
}
.getStarted-dialog-thank-you-description {
  font-size: 11px;
  font-weight: 500;
  margin-top: 2%;
  color: rgb(110, 109, 109);
  line-height: 1.7;
}
@media (max-width: 799px) {
  .getStarted-dialog-header-text-image-container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .getStarted-dialog-header-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .getStarted-dialog-header-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
  }
  .getStarted-dialog-header-image {
    width: 40%;
    margin-top: 2%;
  }
  .getStarted-header-text-description {
    text-align: center;
  }
  .getStarted-dialog-name-email-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .getStarted-dialog-name-container {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .getStarted-dialog-email-container {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .getStarted-dialog-phone-dmatAccount-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3%;
    justify-content: center;
    align-items: center;
  }
  .getStarted-dialog-phone-container {
    width: 86%;
    display: flex;
    flex-direction: column;
  }
  .getStarted-dialog-dmatAccount-question {
    display: flex;
    width: 100%;
    height: 55px;
    justify-content: center;
    align-items: flex-end;
    font-size: 15px;
    font-weight: 400;
  }
  .getStarted-dialog-dmatAccount-selector {
    width: 100%;
    margin-top: 2%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .getStarted-dialog-dmatAccount-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .getStarted-dialog-send-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .getStarted-dialog-textFields-container {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .getStarted-dialog-message-container {
    width: 90%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .getStarted-dialog-thank-you-message {
    width: 350px;
  }
}
