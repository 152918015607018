.dialogRetiermentPlanBackgroundImage {
  background-image: url("../../image/homepage_retirementplan.svg");
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yearSelector {
  color: black;
}

.dialogDreamCarBackgroundImage {
  background-image: url("../../image/homepage_dreamcar.svg");
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #ffff;
}
.dialogDreamHomeBackgroundImage {
  background-image: url("../../image/homepage_home.svg");
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalAmountInput {
  outline: "none";
  height: "30px";
  border: "1.5px solid gray";
  border-radius: "5px";
  background-color: "rgba(0, 0, 0, 0)";
  width: "78%";
  color: "#c1bebe";
  text-align: "left";
  padding-left: "10px";
}
.dialogMarriagePlanBackgroundImage {
  background-image: url("../../image/homepage_marriageplan.svg");
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogEductionPlanBackgroundImage {
  background-image: url("../../image/homepage_educationplan.svg");
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogInvestmentPlanBackgroundImage {
  background-image: url("../../image/homepage_investmentplan.svg");
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogGrowYourMoneyBackgroundImage {
  background-image: url("../../image/homepage_moneygrowth.svg");
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogIncomePlanBackgroundImage {
  background-image: url("../../image/homepage_income.svg");
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogTravelPlanBackgroundImage {
  background-image: url("../../image/homepage_travelplan.svg");
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogTransparentBackground {
  background-color: #000000f2;
  background-color: rgba(0, 0, 0, 0.94902);
  flex: 1;
  display: flex;
  height: 500px;
}
.dialog-body-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}
.dialog-cancle-icon-container {
  width: 97%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
}
.dialog-gturns-icon-container {
  height: 80px;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog-service-heading-container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  font-size: 21px;
  font-weight: 300;
  text-transform: uppercase;
}
.dialog-dream-car-period-selector {
  height: 100px;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.dialog-no-of-years-question-text-and-text-box-container {
  height: 120px;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 700px) {
  .dialog-dream-car-text-box-and-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 56%;
  }
  .dialog-dream-car-question-container {
    color: #b7b7b7;
    font-weight: 300;
    font-size: 15px;
    width: 47%;
    margin-right: 3%;
    text-align: center;
  }
  .selection-control {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .native-select {
    min-width: 263px;
    height: 38px;
    background: transparent;
    color: #a7a0a0;
    border-radius: 5px;
    border: 2px solid #a7a0a0;
    outline: none;
  }
  .amount-select {
    outline: none;
    height: 38px;
    border: 1.5px solid gray;
    border-radius: 5px;
    background-color: transparent;
    min-width: 263px;
    color: #c1bebe;
    text-align: left;
    padding-left: 10px;
  }
  .dialog-dream-car-investment-text-box-container {
    width: 56%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .error-message {
    color: red;
    font-size: 13px;
    padding-top: 8px;
    min-width: 263px;
    text-align: left;
    height: 15px;
  }
}
.dialog-dream-car-text-box-container {
  width: 65%;
}
.dialog-dream-car-investment-question-and-text-box-container {
  height: 120px;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialog-dream-car-next-back-buttons {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 699px) {
  .dialog-dream-car-question-container {
    color: #b7b7b7;
    font-weight: 300;
    font-size: 15px;
    height: 15px;
    width: 100%;
    text-align: center;
  }
  .native-select {
    min-width: 240px;
    height: 38px;
    background: transparent;
    color: #a7a0a0;
    border-radius: 5px;
    border: 2px solid #a7a0a0;
    outline: none;
  }
  .amount-select {
    outline: none;
    height: 38px;
    border: 1.5px solid gray;
    border-radius: 5px;
    background-color: transparent;
    min-width: 240px;
    color: #c1bebe;
    text-align: left;
    padding-left: 10px;
    outline: none;
  }
  .dialog-dream-car-period-selector {
    flex-direction: row;
    width: 100%;
    height: 60px;
  }
  .dialog-gturns-icon-container {
    height: 60px;
  }
  .dialog-service-heading-container {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    width: 90%;
  }
  .dialog-no-of-years-question-text-and-text-box-container {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .dialog-dream-car-text-box-and-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .dialog-dream-car-investment-question-and-text-box-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
  }
  .dialog-no-of-years-question-text-and-text-box-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .dialog-dream-car-investment-text-box-container {
    width: 85%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .dialog-dream-car-next-back-buttons {
    width: 100%;
    height: 60px;
  }
  .error-message {
    color: red;
    font-size: 10px;
    padding-top: 5px;
    width: 220px;
    height: 15px;
  }
}
.dialogBox ::-webkit-scrollbar {
  width: 0;
  display: none;
}
select option {
  background: #363636;
  border: 0px solid transparent;
}
select option[value] {
  color: #e4dede;
  font-size: 14px;
}
