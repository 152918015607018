.HowMuchRiskCard-main-container {
  width: 100%;
}
.HowMuchRiskCard-body-container {
  width: 100%;
  height: 100%;
}
.HowMuchRiskCard-body-card-container {
  flex-direction: row;
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: white;
  text-align: left;
  border-radius: 4px;
}
.HowMuchRiskCard-body-card-image-container {
  width: 30%;
  margin: 1%;
  max-height: 90px;
  height: 85px;
}
.HowMuchRiskCard-body-card-text-container {
  width: 60%;
  display: flex;
  margin: 1%;
  flex-direction: column;
  justify-content: space-between;
}
.HowMuchRiskCard-text-Date-Read-more-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.HowMuchRiskCard-text-header-container {
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  color: hsla(0, 0%, 20%, 0.859);
  text-transform: capitalize;
}
.HowMuchRiskCard-text-Read-more-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #ffd118;
  white-space: nowrap;
}
.HowMuchRiskCard-text-Read-more-container:hover {
  cursor: pointer;
}
.HowMuchRiskCard-text-Date-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #bfbcbc;
}
.HowMuchRiskCard-text-Name-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #bfbcbc;
  width: 100%;
}

@media (min-width: 1400px) {
  .HowMuchRiskCard-body-card-container {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: white;
    border-radius: 26px;
    text-align: left;
  }
  .HowMuchRiskCard-text-Date-Read-more-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .HowMuchRiskCard-text-header-container {
    width: 100%;
    font-weight: normal;
    font-size: 17px;
    color: hsla(0, 0%, 20%, 0.859);
  }
  .HowMuchRiskCard-text-Name-container {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    color: #bfbcbc;
    width: 100%;
  }
  .HowMuchRiskCard-text-Read-more-container {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    color: #ffd118;
  }
  .HowMuchRiskCard-text-Date-container {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    color: #bfbcbc;
  }
}
