.TransparentBlackLayer {
  background-color: rgba(17, 17, 17, 0.839);
  height: 100%;
  width: 100%;
}

.aboutUs-image-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: whitesmoke;
}
@media (min-width: 600px) {
  .aboutUs-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin: 6%;
    margin-top: 15%;
    margin-bottom: 10%;
  }
  .heading-container {
    display: flex;
    flex: 1;
    color: rgb(255, 255, 255);
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
  }
  .aboutUs-description {
    font-weight: normal;
    margin-top: 3%;
    line-height: 2em;
    letter-spacing: 0.3px;
    font-size: 15px;
    color: #d7dce2d9;
    text-align: justify;
  }
  .carousel {
    z-index: 9000;
    margin-bottom: 5%;
  }
  .sliderImageContainer {
    outline: none;
  }
  .aboutUs-heading {
    font-size: 45px;
    font-weight: 300; /* font-variant: small-caps; */
  }
}
@media (max-width: 599px) {
  .aboutUs-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heading-container {
    width: 85%;
    height: 60%;
    display: flex;
    color: rgb(255, 255, 255);
    flex-direction: column;
    letter-spacing: 0.5px;
    line-height: 4em;
    margin-top: 20%;
    margin-bottom: 10%;
  }
  .aboutUs-heading {
    font-size: 35px;
    font-weight: 300;
    text-align: left;
  }
  .aboutUs-description {
    font-weight: normal;
    line-height: 1.5em;
    font-size: 14px;
    text-align: left;
    color: rgb(189, 188, 188);
    margin-top: 10%;
  }
}
@media (min-width: 1800px) {
  .aboutUs-heading {
    font-size: 45px;
    font-weight: 300; /* font-variant: small-caps; */
  }
  .aboutUs-description {
    font-size: 18px;
  }
}
