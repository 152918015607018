.blog-image-container {
  width: 100%;
  margin-top: 5%;
}
.blog-image {
  width: 100%;
}
@media (max-width: 599px) {
  .blog-image {
    width: 100%;
  }
}
