.TransparentBlackLayer {
  background-color: rgba(17, 17, 17, 0.839);
  height: 100%;
  width: 100%;
}
/*HOW IT WORKS MAIN COMPONENT SECTION STYLES */
.howItWorksMobileView {
  display: none;
}
.howItWorks-main-container {
  width: 100%;
}
.howItWorks-body-container {
  width: 100%;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.howItWorks-body-contents {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15%;
}
.howItWorks-dashed-line-div1 {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 0.5px solid black; */
}
.howItWorks-dashed-line1 {
  width: 3%;
  height: 100%;
  border-right-style: dashed;
  border-width: 2px;
  border-bottom-right-radius: 20px;
  border-color: #d5b441;
}
.howItWorks-dashed-line-div2 {
  width: 96%;
  height: 1px;
  display: flex;
  margin-left: 2%;
  /* border: 0.5px solid black; */
}
.howItWorks-dashed-line2 {
  width: 36%;
  height: 1%;
  margin-left: 14%;
  border-bottom-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
}
.howItWorks-dashed-line-div3 {
  width: 100%;
  height: 150px;
  display: flex;
  /* border: 0.5px solid black; */
}
.howItWorks-dashed-line3 {
  width: 1%;
  height: 100%;
  margin-left: 14%;
  border-left-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
  border-top-left-radius: 25px;
}
.howItWorks-card1-container {
  width: 100%;
  height: 245px;
  /* border: 0.5px solid black; */
  display: flex;
  flex-direction: row;
}
.howItWorks-card-no-1 {
  width: 35%;
  /* border: 1px solid blue; */
  display: flex;
}
.howItWorks-card-count-1 {
  width: 10%;
  /* border: 1px solid brown; */
  color: #fae89d;
  font-size: 65px;
  font-weight: 600;
  padding-top: 50px;
}
.howItWorks-card-no-1-text {
  width: 40%;
  height: 100%;
  /* border: 1px solid purple; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3%;
}
.howItWorks-card1-circle-section {
  height: 200px;
  width: 200px;
  background: #d4d4d4;
  border-radius: 150px;
  margin-left: 15%;
}
.howItWorks-card1-square-section {
  top: 20px;
  left: 20px;
  height: 230px;
  width: 230px;
  position: relative;
  background: white;
  box-shadow: 6px 0px 23px 1px rgba(0, 0, 0, 0.2),
    5px -1px 15px -7px rgba(0, 0, 0, 0.14),
    0px -1px 1px -2px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.howItWorks-card-text-heading {
  font-size: 21px;
  opacity: 0.8;
  font-weight: 500;
  margin-top: 15%;
  text-align: left;
}
.howItWorks-card-text-description {
  font-size: 17px;
  font-weight: 400;
  line-height: 2rem;
  margin-top: 5%;
  text-align: left;
  opacity: 0.8;
}
.howItWorks-dashed-line-div4 {
  width: 100%;
  height: 100px;
  display: flex;
}
.howItWorks-dashed-line4 {
  width: 1%;
  height: 100%;
  margin-left: 14%;
  border-left-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
  border-bottom-left-radius: 25px;
}
.howItWorks-dashed-line-div5 {
  width: 100%;
  height: 1px;
  display: flex;
}
.howItWorks-dashed-line5 {
  width: 68%;
  height: 1%;
  margin-left: 15%;
  border-bottom-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
}
.howItWorks-dashed-line-div6 {
  width: 100%;
  height: 100px;
  display: flex;
}
.howItWorks-dashed-line6 {
  width: 1%;
  height: 100%;
  margin-left: 83%;
  border-right-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
  border-top-right-radius: 25px;
}
.howItWorks-card2-container {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: row;
}
.howItWorks-card-count-2 {
  width: 10%;
  color: #fae89d;
  font-size: 65px;
  font-weight: 600;
  padding-top: 50px;
  margin-left: 10%;
}
.howItWorks-card-no-2-text {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3%;
}
.howItWorks-card-no-2-text-heading {
  font-size: 21px;
  opacity: 0.8;
  font-weight: 500;
  margin-top: 15%;
  text-align: left;
}
.howItWorks-card-no-2-text-description {
  font-size: 17px;
  font-weight: 400;
  line-height: 2rem;
  margin-top: 5%;
  text-align: left;
  opacity: 0.8;
}
.howItWorks-card-no-2-container {
  width: 35%;
  display: flex;
  justify-content: flex-end;
  margin-right: 7%;
}
.howItWorks-card-no-2-circle-container {
  height: 200px;
  width: 200px;
  background: #d4d4d4;
  border-radius: 150px;
}
.howItWorks-card-no-2-square-coantainer {
  top: 20px;
  right: 45px;
  height: 230px;
  width: 230px;
  position: relative;
  background: white;
  box-shadow: 4px 0px 23px 1px rgba(0, 0, 0, 0.2),
    -2px -1px 15px -7px rgba(0, 0, 0, 0.14),
    0px -1px 1px -2px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.howItWorks-card-no-3 {
  width: 35%;
  display: flex;
  align-items: flex-end;
}
.howItWorks-card3-circle3-section {
  height: 200px;
  width: 200px;
  background: #d4d4d4;
  border-radius: 150px;
  margin-left: 20%;
}
.howItWorks-card3-square3-section {
  bottom: 50px;
  right: 50px;
  height: 230px;
  width: 230px;
  position: relative;
  background: white;
  box-shadow: 0px 2px 23px 0px rgba(0, -0, 0, 0.2),
    5px -5px 0px -7px rgba(0, 0, 0, 0.14), 0px -1px 0px -1px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.howItWorks-card-no-4 {
  width: 35%;
  display: flex;
  align-items: flex-end;
}
.howItWorks-card4-circle4-section {
  height: 200px;
  width: 200px;
  background: #d4d4d4;
  border-radius: 150px;
  margin-left: 35%;
}
.howItWorks-card4-square4-section {
  bottom: 55px;
  left: 20px;
  height: 230px;
  width: 230px;
  position: relative;
  background: white;
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2),
    5px -1px 15px -7px rgba(0, 0, 0, 0.14),
    0px -1px 1px -2px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.howItWorks-card-no-6-square-coantainer {
  top: 25px;
  right: 45px;
  height: 230px;
  width: 230px;
  position: relative;
  background: white;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2),
    -2px -1px 1px -7px rgba(0, 0, 0, 0.14),
    0px -1px 1px -2px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.howItWorks-dashed-line-div7 {
  width: 100%;
  height: 100px;
  display: flex;
}
.howItWorks-dashed-line7 {
  width: 1%;
  height: 100%;
  margin-left: 83%;
  border-right-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
  border-bottom-right-radius: 25px;
}
.howItWorks-dashed-line-div8 {
  width: 100%;
  height: 150px;
  display: flex;
}
.howItWorks-dashed-line8 {
  width: 1%;
  height: 100%;
  margin-left: 14%;
  border-left-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
  border-top-left-radius: 25px;
}
.howItWorks-card1-square-section:hover,
.howItWorks-card4-square4-section:hover,
.howItWorks-card3-square3-section:hover,
.howItWorks-card-no-2-square-coantainer:hover,
.howItWorks-card-no-6-square-coantainer:hover {
  transform: scale(1.2);
  z-index: 1;
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
}
.howItWorks-card-text-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.howItWorks-card-number-text-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: left;
  color: #fae89d;
  font-size: 40px;
  font-weight: 700;
}
.howItWorks-card-description-text-container {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: left;
  font-size: 19px;
  font-weight: 500;
  opacity: 0.7;
  /* letter-spacing: 0.3px; */
}
@media (max-width: 400px) {
  .howItWorks-body-contents {
    display: none;
  }
  .howItWorksMobileView {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
  }
  .howItWorks-card-main-container {
    width: 300px;
    height: 450px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .howItWorks-card-image-container {
    flex: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: row;
  }
  .howItWorks-card1-circle-section {
    height: 200px;
    width: 200px;
    background: #d4d4d4;
    border-radius: 150px;
    margin-left: 10%;
  }
}
@media (max-width: 600px) {
  .howItWorks-body-contents {
    display: none;
  }
  .howItWorksMobileView {
    display: flex;
    width: 100%;

    justify-content: center;
    align-items: flex-start;
    padding-bottom: 10%;
  }
  .howItWorks-card-main-container {
    width: 300px;
    height: 450px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .howItWorks-card-image-container {
    flex: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .howItWorks-card1-circle-section {
    height: 200px;
    width: 200px;
    background: #d4d4d4;
    border-radius: 150px;
    margin-left: 10%;
  }
}
@media (max-width: 1000px) and (min-width: 601px) {
  .howItWorks-body-contents {
    display: none;
  }
  .howItWorksMobileView {
    display: flex;
    width: 100%;

    justify-content: center;
    align-items: flex-start;
    padding-bottom: 10%;
  }
  .howItWorks-card-main-container {
    width: 300px;
    height: 450px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .howItWorks-card-image-container {
    flex: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .howItWorks-card1-circle-section {
    height: 200px;
    width: 200px;
    background: #d4d4d4;
    border-radius: 150px;
    margin-left: 10%;
  }
}
@media (min-width: 1800px) {
  .howItWorks-card1-circle-section {
    height: 250px;
    width: 250px;
    background: #d4d4d4;
    border-radius: 150px;
    margin-left: 15%;
  }
  .howItWorksMobileView {
    display: none;
  }
  .howItWorks-card1-square-section {
    height: 270px;
    width: 270px;
  }
  .howItWorks-card-text-heading {
    font-size: 29px;
    opacity: 0.8;
    font-weight: 500;
    margin-top: 10%;
  }
  .howItWorks-card4-circle4-section,
  .howItWorks-card3-circle3-section,
  .howItWorks-card-no-2-circle-container {
    height: 250px;
    width: 250px;
  }
  .howItWorks-card4-square4-section,
  .howItWorks-card3-square3-section,
  .howItWorks-card-no-2-square-coantainer,
  .howItWorks-card-no-6-square-coantainer {
    height: 270px;
    width: 270px;
  }
  .howItWorks-card-no-2-text-heading,
  .howItWorks-card-text-heading {
    font-size: 27px;
  }
  .howItWorks-card-no-2-text-description,
  .howItWorks-card-text-description {
    font-size: 20px;
  }
  .howItWorks-card-count-1,
  .howItWorks-card-count-2 {
    font-size: 80px;
  }
}
/* HOW IT WORKS HEADER SECTION STYLES */
