.blog-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.slider-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.blog-slide {
  display: inline-block;
  height: 400px;
  width: 100%;
}

.circle1 {
  position: absolute;
  top: 85%;
  font-size: 50px;
  left: 45%;
  /*z-index: 999;*/
  background: transparent;
  color: #afa9a9a8;
}

.circle2 {
  position: absolute;
  top: 85%;
  font-size: 50px;
  left: 50%;
  /*z-index: 999;*/
  background: transparent;
  color: #afa9a9a8;
}
.circle3 {
  position: absolute;
  top: 85%;
  font-size: 50px;
  left: 55%;
  /* z-index: 999;*/
  background: transparent;
  color: #afa9a9a8;
}
.circle4 {
  position: absolute;
  top: 85%;
  font-size: 50px;
  left: 60%;
  /* z-index: 999;*/
  background: transparent;
  color: #afa9a9a8;
}
/* .circle1:hover,
.circle2:hover,
.circle3:hover,
.circle4:hover {
  color: #1715157a;
} */
.fa-arrow-right:before,
.fa-arrow-left:before {
  color: #222;
}
@media (max-width: 800px) {
  .circle1 {
    position: absolute;
    top: 85%;
    font-size: 50px;
    left: 40%;
    /*z-index: 999;*/
    background: transparent;
    color: #afa9a9a8;
  }

  .circle2 {
    position: absolute;
    top: 85%;
    font-size: 50px;
    left: 45%;
    /*z-index: 999;*/
    background: transparent;
    color: #afa9a9a8;
  }
  .circle3 {
    position: absolute;
    top: 85%;
    font-size: 50px;
    left: 50%;
    /* z-index: 999;*/
    background: transparent;
    color: #afa9a9a8;
  }
  .circle4 {
    position: absolute;
    top: 85%;
    font-size: 50px;
    left: 55%;
    /* z-index: 999;*/
    background: transparent;
    color: #afa9a9a8;
  }
}
/* @media (max-width: 800px) {
  .circle1 {
    position: absolute;
    top: 80%;
    left: 45%;
    z-index: 999;
    background: transparent;
    color: #fffafa;
  }

  .circle2 {
    position: absolute;
    top: 80%;
    left: 50%;
    z-index: 999;
    background: transparent;
    color: #fffafa;
  }
  .circle3 {
    position: absolute;
    top: 80%;
    left: 55%;
    z-index: 999;
    background: transparent;
    color: #fffafa;
  }
  .circle4 {
    position: absolute;
    top: 80%;
    left: 60%;
    z-index: 999;
    background: transparent;
    color: #fffafa;
  }
} */
.bottom-left {
  display: flex;
  color: white;
  height: inherit;
  align-items: flex-end;
  top: 0%;
  position: absolute;
  width: 100%;
}
.titleSlide {
  padding: 1em 2em;
  position: relative;
  bottom: 16%;
  color: black;
  background: #ffffffcf;
  min-width: 52%;
  box-shadow: 1px 2px 5px grey;
}
