.gallery-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(34, 29, 23);
  color: rgb(255, 255, 255);
}

.gallery-header-text-container {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-weight: 300;
  padding-top: 7%;
}
.gallery-image-video-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-evenly;
  margin: 5%;
}
.image-holder {
  width: 30%;
  height: 300px;
  border: 2px solid #ffd118;
  background-image: url("../image/IMG_0492.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 2px;
}
.holder-button-background {
  background-color: #00000070;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.holder-button-background:hover {
  background-color: #000000b2;
}

.holder-button {
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  margin-bottom: 10%;
}

.video-holder {
  width: 30%;
  height: 300px;
  border: 2px solid #ffd118;
  background-image: url("../image/IMG_7827.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2px;
}
@media (max-width: 699px) {
  .gallery-image-video-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-evenly;
    align-items: center;
  }
  .gallery-header-text-container {
    font-size: 28px;
  }
  .image-holder {
    width: 80%;
    margin: 5%;
  }
  .video-holder {
    width: 80%;
    margin: 5%;
  }
}
