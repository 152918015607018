.pagination-main-container {
  margin-top: 30px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
}
.pagination-buttons-container {
  display: flex;
  width: 97%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
