.map-container {
  width: 100%;
  background-color: rgb(33, 33, 33);
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
.grid-container {
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 5%;
}
.contactus-heading {
  font-size: 36px;
  font-weight: 300;
  color: white;
}
.map-card-container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 15px;
}
.card-image-container {
  height: 160px;
  min-width: 306px;
  background-color: rgb(241, 241, 241);
}
.map-card-header {
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 10px;
  letter-spacing: 0.6px;
  font-weight: 300;
}
.card-info-container {
  min-height: 170px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  padding-top: 0;
}
.card-info-description {
  font-size: 14px;
  text-align: left;
  color: rgb(173, 173, 173);
  margin-top: 15px;
  line-height: 1.5em;
}
.phone-no-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.card-deails-sub-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* padding-top: 10px; */
}
.map-link-anchor {
  color: rgb(231, 204, 83);
  font-size: 12px;
  text-decoration: none;
  border-bottom: 1px solid rgb(231, 204, 83);
  margin-top: 25px;
}
.business-proposal-sub-header {
  color: rgb(230, 227, 227);
  font-size: 17px;
  padding-top: 10px;
}
@media (min-width: 600px) {
  .grid-container {
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 599px) {
  .grid-container {
    display: flex;
    flex-direction: column;
  }
  .contactus-heading {
    font-size: 28px;
  }
}
