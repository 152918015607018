.certificaton-container {
  width: 100%;
  display: flex;
  height: 600px;
}
.certificate-background-image {
  background-image: url("../image/Gturns-aboutuspage-Logo_BG.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto;
  height: 100%;
  width: 100%;
  background-color: black;
}
.certificate-black-background {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.856);
}
.certificate-carousel-heading {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-weight: 300;
  padding-top: 7%;
}
.certificate-image-carousel {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.certificate-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.certificate-slider-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.certificateslide {
  display: inline-block;
  height: 100%;
  width: 33%;
}
.certificate-image-holder {
  display: none;
}
.arrow {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: transform ease-in 0.1s;
}
.nextArrow {
  position: absolute;
  top: 50%;
  right: 25px;
  font-size: 40px;
  background: #0e0e0e6b;
}
.backArrow {
  position: absolute;
  top: 50%;
  left: 25px;
  font-size: 40px;
  background: #0e0e0e6b;
}
.fa-arrow-right:before,
.fa-arrow-left:before {
  color: #222;
}
.carousel-image-Dialog-Component {
  width: 699px;
  display: contents;
}
.MuiDialog-paperWidthMd,
.MuiDialog-paper {
  max-width: 480px;
}
@media (max-width: 799px) {
  .certificate-slider {
    display: none;
  }
  .certificaton-container {
    width: 100%;
    display: flex;
    height: 100%;
  }
  .certificate-image-holder-background {
    height: 100%;
    width: 100%;
    background-color: rgba(2, 2, 2, 0.94);
  }
  .certificate-background-image {
    background-image: url("../image/Gturns-aboutuspage-Logo_BG.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    height: 100%;
    width: 100%;
    background-color: black;
  }
  .certificate-black-background {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.856);
  }
  .certificate-carousel-heading {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 28px;
    padding-top: 7%;
  }
  .certificate-image-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .certificate-modal-container {
    position: absolute;
    left: 15%;
    top: 10%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 80px;
    flex-direction: column;
    display: flex;
  }
  .certificate-heading {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    font-size: 38px;
    font-weight: 500;
    padding-top: 8%;
    padding-bottom: 8%;
  }
  .certificate-uttam-image,
  .certificate-lakshmi-image,
  .certificate-sharavan-image {
    width: 100%;
  }
}
