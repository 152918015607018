.blog-YMAL-container {
  padding: 0em 1em;
}
.blog-YMAL-heading-container {
  margin: 1.5em 0;
  font-weight: normal;
  font-size: 2em;
  font-weight: 300;
  color: hsla(0, 0%, 20%, 0.859);
  text-align: center;
}
.blog-YMAL-body-container {
  width: 100%;
  margin-top: 3%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 15%;
}
.blog-YMAL-links-container-odd {
  min-width: 380px;
  flex-direction: row;
  display: flex;
  height: 120px;
  /* background-color: white; */
  margin: 2%;
  padding: 15px;
  color: rgb(88, 88, 88);
  justify-content: space-between;
  border-radius: 5px;
  /* box-shadow: 0px 0px 20px #00000021; */
}
.blog-YMAL-links-container {
  max-width: 380px;
  flex-direction: row;
  display: flex;
  height: 120px;
  background-color: white;
  margin: 2%;
  padding: 15px;
  color: rgb(88, 88, 88);
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #00000021;
}
.blog-YMAL-links-image-container {
  width: 30%;
  /* border: 1px solid black; */
}
.blog-YMAL-links-text-container {
  width: 65%;
  /* border: 1px solid black; */
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}
.blog-YMAL-links-Date-Read-more-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.blog-YMAL-links-Name-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #bfbcbc;
}
.blog-YMAL-links-Date-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #bfbcbc;
}
.blog-YMAL-links-Read-more-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #ffd118;
}
.blog-YMAL-links-heading-name-container {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}
@media (max-width: 599px) {
  .blog-YMAL-body-container {
    width: 100%;
    margin-top: 10%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .blog-YMAL-links-container-odd {
    display: none;
  }
  .blog-YMAL-links-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    height: 125px;
    background-color: white;
    justify-content: space-between;
  }
  .blog-YMAL-heading-container {
    margin-top: 10%;
    font-weight: normal;
    font-size: calc(30px + 1.4vmin);
    color: hsla(0, 0%, 20%, 0.859);
  }
}
@media (min-width: 1400px) {
  .blog-YMAL-links-container {
    width: 420px;
    flex-direction: row;
    display: flex;
    height: 140px;
    background-color: white;
    margin: 2%;
    padding: 15px;
    justify-content: space-between;
    border-radius: 2px;
    box-shadow: 0px 0px 20px #00000021;
  }
  .blog-YMAL-links-heading-name-container {
    font-size: 18px;
    text-align: left;
  }
  .blog-YMAL-links-Name-container {
    font-size: 15px;
  }
  .blog-YMAL-links-Date-container {
    font-size: 15px;
  }
  .blog-YMAL-links-Read-more-container {
    font-size: 15px;
  }
}
