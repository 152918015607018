@media (min-width: 800px) {
  .Header-Container {
    height: 80px;
    width: 100%;
    position: relative;
    z-index: 900;
  }
  .Header {
    align-items: center;
    justify-content: center;
    background: transparent;
    min-height: 80px;
    text-align: center;
    flex-direction: row;
    display: flex;
    z-index: 9000;
    color: rgb(228, 226, 226);
  }
  .Content {
    padding: 16px;
  }
  .Sticky {
    position: fixed;
    color: rgb(241, 241, 241);
    box-shadow: 0 8px 6px -6px rgb(44, 38, 38);
    top: 0;
    width: 100%;
    animation-name: headerBar;
    animation-duration: 300ms;
    animation-delay: 300ms;
    animation-fill-mode: both;
  }
  .Sticky + .Content {
    padding-top: 102px;
  }
  @keyframes headerBar {
    from {
      top: -80px;
      background-color: rgb(85, 85, 85);
    }
    to {
      top: 0px;
      background-color: black;
    }
  }
  .header-logo-container {
    flex: 0.6;
    display: flex;
    padding-left: 5%;
    align-items: center;
  }

  .header-logo {
    width: 55px;
    margin-top: 1%;
  }
  .header-logo:hover {
    cursor: pointer;
  }
  .header-logo-hover-changer {
    text-decoration: none;
    cursor: default;
  }
  .header-logo-hover-changer .header-company-name {
    display: none;
  }
  /* .header-logo-hover-changer:hover .header-logo {
    display: none;
  }
  .header-logo-hover-changer:hover .header-company-name {
    display: inline;
    animation-name: companyName;
    animation-duration: 500ms;
    animation-delay: 100ms;
    animation-fill-mode: both;
    color: rgb(231, 204, 83);
  } */
  .header-anchor-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 3%;
  }
  .header-anchor-container a {
    display: flex;
    flex-direction: row;
    float: left;
    text-align: center;
    padding: 20px;
    padding-bottom: 7px;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 0.5px;
    border-bottom: 2px solid transparent;
    color: rgba(204, 204, 204, 0.8);
    white-space: nowrap;
  }
  .header-anchor-container a:hover {
    border-bottom: 1px solid rgb(231, 204, 83);
    color: rgb(255, 255, 255);
  }
  .header-anchor-container a#active {
    border-bottom: 1px solid rgb(231, 204, 83);
    color: rgb(255, 255, 255);
  }
  .header-getStartedButton {
    height: 40px;
    width: 23%;
    background-color: #ffd118;
    border: none;
    color: #070707e3;
    border-radius: 3px;
    margin-top: 10px;
    margin-left: 3%;
    font-size: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
  }
  .icon-holder {
    display: none;
    flex-direction: column;
    flex: 1;
  }
  .anchors-holder {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .getStarted-Dialog-Component {
    height: 500px;
    width: 699px;
  }
}

@media (max-width: 799px) {
  .Header-Container {
    height: 80px;
    width: 100%;
    z-index: 900;
  }

  .Header {
    align-items: center;
    justify-content: center;
    background: transparent;
    min-height: 80px;
    text-align: center;
    flex-direction: row;
    display: flex;
    z-index: 9000;
    color: white;
  }
  .swipeableDrawer > * {
    position: relative;
    top: 80px;
  }
  .swipeableMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
  }
  .header-logo-container {
    flex: 0.6;
    display: flex;
    align-items: center;
    margin-left: 5%;
  }
  .header-logo {
    height: 45px;
    margin-top: 1%;
  }
  .header-logo-hover-changer .header-company-name {
    display: none;
  }
  /* .header-logo-hover-changer:hover .header-logo {
    display: none;
  } */
  /* .header-logo-hover-changer:hover .header-company-name {
    display: inline;
    animation-name: companyName;
    animation-duration: 500ms;
    animation-delay: 100ms;
    animation-fill-mode: both;
    color: rgb(231, 204, 83);
  } */
  .header-anchor-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    padding-bottom: 10px;
  }
  .header-anchor-container a {
    display: flex;
    flex-direction: column;
    padding-bottom: 7px;
    margin: 20px;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    border-bottom: 2px solid transparent;
    color: rgba(204, 204, 204, 0.8);
  }
  .header-anchor-container a:hover {
    border-bottom: 1px solid rgb(231, 204, 83);
  }
  .header-anchor-container a#active {
    border-bottom: 1px solid rgb(231, 204, 83);
    color: rgb(255, 255, 255);
  }
  .header-getStartedButton {
    height: 40px;
    width: 35%;
    background-color: #ffd118;
    border: none;
    color: black;
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }
  .Content {
    padding: 16px;
  }
  .Sticky {
    position: fixed;
    color: rgb(241, 241, 241);
    box-shadow: 0 8px 6px -6px rgb(44, 38, 38);
    top: 0;
    width: 100%;
    animation-name: headerBar;
    animation-duration: 500ms;
    animation-delay: 500ms;
    animation-fill-mode: both;
  }
  .Sticky + .Content {
    padding-top: 102px;
  }
  @keyframes headerBar {
    from {
      top: -80px;
      background-color: rgb(85, 85, 85);
    }
    to {
      top: 0px;
      background-color: black;
    }
  }
  .icon-holder {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding-right: 10%;
  }

  .anchors-holder {
    display: none;
    flex-direction: row;
    flex: 1;
  }
  .getStarted-Dialog-Component {
    margin: 0%;
  }
}
