@media (min-width: 800px) {
  /* width */
  .banner-backgroundImage::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  .banner-backgroundImage::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .banner-backgroundImage::-webkit-scrollbar-thumb {
    background: rgb(119, 115, 115);
    border-radius: 10px;
  }

  /* Handle on hover */
  .banner-backgroundImage::-webkit-scrollbar-thumb:hover {
    background: #ffd118;
  }
}
.banner-backgroundImage {
  overflow-y: hidden;
}
.market-analysis-image {
  top: -40px;
}
.data-driven-image {
  top: 82px;
}
@media (max-width: 599px) {
  .banner-backgroundImage {
    overflow-y: auto;
  }
  .market-analysis-image {
    top: -40px;
  }
  .data-driven-image {
    top: 89px;
  }
}
