.blogsList-researchDesk-main-container {
  width: 100%;
  margin-top: 8%;
  margin-bottom: 5%;
}
.blogsList-researchDesk-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogsList-researchDesk-Heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 55px;
  align-items: center;
  margin: 0em 2em;
}
.researchDesk-Heading-bottom-line {
  height: 1px;
  background: #80808091;
}
.researchDesk-Research-desk-text {
  font-size: 20px;
  color: darkslategray;
}
.researchDesk-View-All-text {
  font-size: 18px;
  color: #ffd118;
}
.blogsList-researchDesk-body-container {
  width: 100%;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.research-desk-main-div {
  display: flex;
  flex-direction: column;
  width: 330px;
}
.research-desk-image-container {
  width: 100%;
  height: 170px;
  cursor: pointer;
}
.research-desk-text-container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid #eae6e6;
}
.research-desk-current-status-container {
  width: 100%;
  height: 40px;
  flex-direction: row;
  display: flex;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.research-desk-text-container-header {
  display: flex;
  color: #464646;
  font-weight: 500;
  justify-content: flex-start;
  margin: 15px 15px 0px 15px;
  align-items: baseline;
  font-size: 15px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
}
.research-desk-text-container-name-date {
  display: flex;
  margin: 9px 0 10px 14px;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  color: #959e9e;
  font-size: 12px;
}
.research-desk-text-container-Description {
  margin: 20px 0 20px 15px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 95%;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #5f5c5c;
  line-height: 1.7;
}
.research-desk-current-status-like-view-container {
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  padding: 10px;
  opacity: 0.9;
  margin-left: 10px;
}
.research-desk-current-status-Learn-more-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffd118;
  font-size: 12px;
  cursor: pointer;
  margin-right: 10px;
}

.research-desk-current-status-likes-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(110, 109, 109);
  padding: 8px;
}
.ReSearch-desk-Paper {
  margin-bottom: 50px;
  border-radius: 8px;
}
.research-desk-current-status-views-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(110, 109, 109);
  padding: 8px;
}
.blogListViews,
.blogListLikes {
  margin-left: 5px;
}
.ReSearch-desk-Paper:hover {
  box-shadow: 40px 40px 60px rgba(0, 0, 0, 0.2);
}
@media (max-width: 599px) {
  .research-desk-text-container-header {
    font-size: 17px;
    width: 100%;
  }
  .research-desk-text-container-name-date {
    font-size: 15px;
    margin: 15px;
  }
  .research-desk-text-container-Description {
    font-size: 15px;
  }
  .blogListViews,
  .blogListLikes {
    display: contents;
  }
  .ReSearch-desk-Paper {
    margin-bottom: 15px;
    border-radius: 8px;
  }
  .research-desk-current-status-Learn-more-container {
    color: #ffd118;
    font-size: 15px;
  }
  .research-desk-current-status-like-view-container {
    font-size: 12px;
  }
  .research-desk-main-div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .blogsList-researchDesk-container {
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .research-desk-main-div {
    display: flex;
    flex-direction: column;
    width: 320px;
    border-radius: 5px;
  }
  .research-desk-current-status-container {
    width: 100%;
    height: 100px;
    flex-direction: row;
    display: flex;
    background-color: white;
  }
  .research-desk-image-container {
    width: 100%;
    height: 200px;
  }
  .research-desk-text-container {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-bottom: 0.5px solid #eae6e6;
  }
  .research-desk-text-container-header {
    font-size: 16px;
  }
  .research-desk-text-container-name-date {
    font-size: 15px;
  }
  .research-desk-text-container-Description {
    font-size: 14px;
  }
  .research-desk-current-status-like-view-container {
    font-size: 14px;
  }
  .research-desk-current-status-Learn-more-container {
    font-size: 14px;
  }
  .research-desk-current-status-container {
    height: 45px;
  }
}
