.FooterContainer {
  min-height: 56px;
  background-color: rgb(0, 0, 0);
  color: rgba(204, 204, 204, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.footer-anchor-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 10%;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
}
.footer-copyrights-container {
  display: flex;
  flex-direction: row;
  flex: 0.8;
  margin-left: 27%;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.03em;
}
.footer-anchor {
  font-size: 13px;
  color: rgba(204, 204, 204, 0.8);
  padding-right: 10px;
  text-decoration: none;
  font-weight: 200;
  letter-spacing: 0.03em;
}
.footer-anchor-sub {
  font-size: 13px;
  color: rgba(204, 204, 204, 0.8);
  padding-right: 10px;
  text-decoration: none;
  font-weight: 300;
  letter-spacing: 0.03em;
}
.footer-anchor:hover {
  cursor: pointer;
}
.fotter-privacy-policy-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.fotter-privacy-policy-Heading-container {
  color: white;
  font-size: 25px;
  margin-left: 20px;
}
.fotter-privicy-policy-body-container {
  padding: 50px;
}
.fotter-privicy-policy-content-header-container {
  font-size: 18px;
  font-weight: 500;
  margin-top: 2%;
  letter-spacing: 0.5px;
}
.privicy-policy-content-container {
  margin-top: 1%;
  line-height: 2;
  letter-spacing: 0.2px;
}
.fotter-unOrdered-list-class {
  margin-left: 1%;
}
@media (max-width: 599px) {
  .footer-copyrights-container {
    display: flex;
    flex-direction: row;
    flex: 0.8;
    margin-left: 0%;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.03em;
    align-items: center;
  }
  .footer-anchor-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-left: 0%;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
  }
  .FooterContainer {
    display: flex;
    flex-direction: column;
    height: 85px;
  }
  .fotter-p-classes {
    margin-top: 2%;
  }
  .fotter-privicy-policy-body-container {
    padding: 20px;
  }
  .fotter-privicy-policy-content-header-container {
    margin-top: 5%;
    letter-spacing: 0.5px;
  }
  .privicy-policy-content-container {
    margin-top: 5%;
    line-height: 1.5;
    letter-spacing: 0.3px;
  }
}
