* {
  margin: 0;
  padding: 0;
}
.services-container {
  background: whitesmoke;
  padding-top: 80px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-heading {
  font-size: 36px;
  color: black;
  font-weight: 300;
  letter-spacing: 0.04em;
  opacity: 0.7;
}
.services-sub-heading {
  font-size: 20px;
  line-height: 1.7;
  color: black;
  opacity: 0.6;
  padding: 2%;
  padding-bottom: 0;
}
.services-body-cotainer {
  width: 80%;
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}
.services-card-container {
  /* height: 400px; */
  width: 335px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
/* .services-cards-Paper {
  background-color: transparent;
  margin: 10px;
  margin-bottom: 50px;
  box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.14);
} */
.services-card-image-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.services-card-image-container {
  position: relative;
  height: 100px;
  width: 100%;
  padding-top: 5px;
  background-color: whitesmoke;
}
.services-card-text-container {
  align-items: flex-start;
  justify-content: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}

.slide-track,
.Partners-body-container {
  display: grid;
  grid-template-columns: 10em 10em 10em 10em;
}

.services-card-text-heading-container {
  height: 60px;
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.3px;
  opacity: 0.7;
  text-align: center;
}
.services-card-text-description-container {
  width: 80%;
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #868686;
  font-size: 14px;
  letter-spacing: 0.4px;
}
.services-card-button-container {
  height: 80px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.service-images-containers {
  top: 65;
  width: 180px;
  height: 180px;
  border-radius: 100px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 0px 20px #00000021;
  -webkit-box-shadow: 0px 0px 20px #00000021;
  z-index: 3000;
  background-color: white;
}
@media (max-width: 599px) {
  .services-heading {
    font-size: 28px;
  }
  .services-sub-heading {
    font-size: 18px;
    margin-top: 8%;
    line-height: 1.5em;
    text-align: center;
  }
  .services-card-text-heading-container {
    font-size: 17px;
  }
}
@media (min-width: 1800px) {
  .services-card-container {
    width: 450px;
  }
  .services-card-text-heading-container {
    font-size: 20px;
  }
  .services-card-text-description-container {
    font-size: 17px;
  }
}
.logo-holder {
  height: 150px;
}
.close-icon-holder {
  padding: 1%;
  font-size: 16px;
  color: #d8d5d5;
}
.close-icon-holder:hover {
  color: rgb(233, 30, 30);
}
.Transparent-blackLayer {
  background-color: rgba(17, 17, 17, 0.839);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
}
.modal-information-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
  flex: 1;
}
.modal-backgroundImage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  width: 85%;
  outline: none;
  background: url("../../image/pexels-photo.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
