.Products-main-container {
  width: 100%;
  height: 300px;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Products-body-container {
  display: flex;
  flex: 0.8;
  justify-content: center;
  align-items: center;
  width: 85%;
}
.product-logo-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.product-logo-container > img {
  width: 250px;
  transition: transform 0.2s;
  cursor: pointer;
}
.product-logo-container > img:hover {
  transform: scale(1.5);
}
@media (min-width: 769px) {
  .Products-body-container {
    flex-direction: row;
  }
}
@media (max-width: 768px) {
  .Products-body-container {
    flex-direction: column;
  }
}
