.team-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}
.team-heading {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  opacity: 0.7;
  letter-spacing: 1px;
  font-size: 36px;
  font-weight: 300;
  padding-top: 8%;
}
.founder-description {
  display: flex;
  flex: 2;
  flex-direction: column;
  text-align: left;
  font-size: 15px;
  line-height: 2;
  color: black;
  opacity: 0.8;
  text-align: left;
}

.founder-socialMedia-icons-holder {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 25px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 200ms;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
.flip-card:hover + .coWorker-Details-front > .coWorker-name {
  display: none;
}
.flip-card:hover + .coWorker-Details-front > .coWorker-position {
  display: none;
}
.flip-card:hover
  + .coWorker-Details-front
  > .coWorker-socialMedia-icons-holder {
  display: none;
}
.coWorker-Details-front {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.flip-card-front {
  position: absolute;
  backface-visibility: hidden;
  background: transparent;
  color: black;
  z-index: 2;
  display: block;
}

.flip-card-front-img {
  border-radius: 200px;
  height: 40%;
  width: 40%;
  border: 2px solid rgb(231, 204, 83);
}

.flip-card-front,
.flip-card-front > * {
  border-radius: 90%;
}
.flip-card-front,
.flip-card-back {
  transform: rotateX(0deg);
}
.coWorker-name {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  padding-top: 0px;
  color: black;
}
.coWorker-position {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #000000ad;
}
.coWorker-socialMedia-icons-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding-top: 2%;
}
.coWorkerDetails {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: normal;
  line-height: 2em;
  margin-bottom: 20px;
  text-align: left;
}

@media (min-width: 600px) {
  .founder-details-container {
    display: flex;
    flex: 0.6;
    flex-direction: row;
    margin: 90px;
    justify-content: center;
    align-items: center;
  }
  .founder-image-container {
    display: flex;
    flex: 1.9;
    flex-direction: column;
  }
  .founder-image > img {
    height: 45%;
    width: 45%;
  }
  .founder-name {
    display: flex;
    flex: 0.5;
    color: black;
    opacity: 0.87;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    padding-top: 20px;
  }
  .founder-position {
    display: flex;
    flex: 0.5;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    padding-top: 8px;
    color: black;
    opacity: 0.7;
  }
  .founder-description {
    margin-right: 10%;
    line-height: 1.8;
  }
  .socialMedia-icon {
    margin: 7px;
    color: #808080;
  }
  .team-coWorkers-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 90px;
    margin-top: 60px;
  }
  .team-coWorkers-subContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-top: 90px;
  }
  .team-coWorker {
    height: 100%;
    width: 100%;
  }
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 205px;
    perspective: 1000px;
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .flip-card-back {
    width: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1),
      0 3px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
    padding: 5%;
    text-align: justify;
    background-color: white;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    z-index: 1;
    position: absolute;
  }
}

@media (max-width: 599px) {
  .team-heading {
    font-size: 28px;
  }
  .founder-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .founder-image > img {
    height: 55%;
    width: 55%;
    margin-top: 10%;
  }
  .founder-name {
    display: flex;
    flex: 0.5;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 500;
    padding-top: 20px;
    color: black;
    opacity: 0.8;
  }
  .founder-position {
    display: flex;
    flex: 0.5;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    padding-top: 12px;
    padding-bottom: 20px;
    color: black;
    opacity: 0.7;
  }
  .founder-description {
    margin: 5%;
    color: black;
    opacity: 0.8;
  }
  .socialMedia-icon {
    margin-right: 7px;
    color: #808080;
  }
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 200px;
    perspective: 1000px;
    margin-top: 10%;
  }
  .coWorker-Details-front {
    margin-bottom: 30%;
  }
  .flip-card-back {
    width: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-box-shadow: 5px 5px 8px -1px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 5px 5px 8px -1px rgba(0, 0, 0, 0.47);
    box-shadow: 5px 5px 8px -1px rgba(0, 0, 0, 0.47);
    padding: 5%;
    text-align: justify;
    color: white;
    background-color: white;
    border: 1px solid rgb(231, 204, 83);
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    z-index: inherit 999;
    position: absolute;
  }
}
