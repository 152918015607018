.Mission-container {
  background-color: #eeeeee;
  padding-top: 8%;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
}
.mission-image-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.mission-heading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(88, 88, 88);
  letter-spacing: 1px;
  font-size: 36px;
  font-weight: 300;
}
.misson-handskake-image,
.mission-capital-protective-image,
.mission-goal-image {
  border-radius: 160px;
  background-color: white;
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.2),
    -2px -1px 1px -7px rgba(0, 0, 0, 0.14),
    0px -1px 1px -2px rgba(0, 0, 0, 0.14);
}

.mission-image-holder {
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mission-image-text {
  font-size: 21px;
}
@media (min-width: 600px) {
  .mission-content {
    display: flex;
    width: 90%;
    flex-direction: row;
    padding-top: 5%;
    margin-top: 0px;
    padding-bottom: 5%;
    justify-content: space-evenly;
    align-items: center;
  }
}
@media (max-width: 599px) {
  .mission-content {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 60px;
    justify-content: space-evenly;
    align-items: center;
    height: 150vh;
  }
  .misson-handskake-image,
  .mission-capital-protective-image,
  .mission-goal-image {
    width: 180px;
    height: 180px;
  }
  .mission-heading {
    font-size: 28px;
  }
  .mission-subContent {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(110, 104, 104);
    text-align: justify;
    line-height: 2;
    font-size: calc(5px + 1.5vmin);
    padding-right: 20px;
    padding-left: 20px;
  }
  /* .mission-content {
    display: flex;
    flex: 2;
    flex-direction: column;
    padding-top: 5%;
    margin-top: 0px;
    padding-bottom: 5%;
    justify-content: center;
    align-items: center;
  } */
  .mission-image-text {
    font-size: 21px;
    font-weight: normal;
  }
}
