@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Roboto";
}
.App {
  text-align: center;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-row: auto; */
  width: 100%;
  height: 100%;
}

.Header-bar {
  min-height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.parallax {
  /* The image used */
  background-image: url(/static/media/AboutUs_BG_1.107c1708.png);
  /* Full height */
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax2 {
  background-image: url(/static/media/architecture-black-and-white-buildings-264594.b9f7eb45.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax3 {
  background-image: url(/static/media/pexels-photo-1803739.752ede97.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.main-tabs-container {
  position: relative;
  top: -80px;
  margin-bottom: -80px;
}

.TransparentBlackLayer {
  background-color: rgba(17, 17, 17, 0.839);
  height: 100%;
  width: 100%;
}

.aboutUs-image-container {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: whitesmoke;
}
@media (min-width: 600px) {
  .aboutUs-container {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    margin: 6%;
    margin-top: 15%;
    margin-bottom: 10%;
  }
  .heading-container {
    display: flex;
    flex: 1 1;
    color: rgb(255, 255, 255);
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
  }
  .aboutUs-description {
    font-weight: normal;
    margin-top: 3%;
    line-height: 2em;
    letter-spacing: 0.3px;
    font-size: 15px;
    color: #d7dce2d9;
    text-align: justify;
  }
  .carousel {
    z-index: 9000;
    margin-bottom: 5%;
  }
  .sliderImageContainer {
    outline: none;
  }
  .aboutUs-heading {
    font-size: 45px;
    font-weight: 300; /* font-variant: small-caps; */
  }
}
@media (max-width: 599px) {
  .aboutUs-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heading-container {
    width: 85%;
    height: 60%;
    display: flex;
    color: rgb(255, 255, 255);
    flex-direction: column;
    letter-spacing: 0.5px;
    line-height: 4em;
    margin-top: 20%;
    margin-bottom: 10%;
  }
  .aboutUs-heading {
    font-size: 35px;
    font-weight: 300;
    text-align: left;
  }
  .aboutUs-description {
    font-weight: normal;
    line-height: 1.5em;
    font-size: 14px;
    text-align: left;
    color: rgb(189, 188, 188);
    margin-top: 10%;
  }
}
@media (min-width: 1800px) {
  .aboutUs-heading {
    font-size: 45px;
    font-weight: 300; /* font-variant: small-caps; */
  }
  .aboutUs-description {
    font-size: 18px;
  }
}

.Mission-container {
  background-color: #eeeeee;
  padding-top: 8%;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
}
.mission-image-container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}

.mission-heading {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(88, 88, 88);
  letter-spacing: 1px;
  font-size: 36px;
  font-weight: 300;
}
.misson-handskake-image,
.mission-capital-protective-image,
.mission-goal-image {
  border-radius: 160px;
  background-color: white;
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.2),
    -2px -1px 1px -7px rgba(0, 0, 0, 0.14),
    0px -1px 1px -2px rgba(0, 0, 0, 0.14);
}

.mission-image-holder {
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mission-image-text {
  font-size: 21px;
}
@media (min-width: 600px) {
  .mission-content {
    display: flex;
    width: 90%;
    flex-direction: row;
    padding-top: 5%;
    margin-top: 0px;
    padding-bottom: 5%;
    justify-content: space-evenly;
    align-items: center;
  }
}
@media (max-width: 599px) {
  .mission-content {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 60px;
    justify-content: space-evenly;
    align-items: center;
    height: 150vh;
  }
  .misson-handskake-image,
  .mission-capital-protective-image,
  .mission-goal-image {
    width: 180px;
    height: 180px;
  }
  .mission-heading {
    font-size: 28px;
  }
  .mission-subContent {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(110, 104, 104);
    text-align: justify;
    line-height: 2;
    font-size: calc(5px + 1.5vmin);
    padding-right: 20px;
    padding-left: 20px;
  }
  /* .mission-content {
    display: flex;
    flex: 2;
    flex-direction: column;
    padding-top: 5%;
    margin-top: 0px;
    padding-bottom: 5%;
    justify-content: center;
    align-items: center;
  } */
  .mission-image-text {
    font-size: 21px;
    font-weight: normal;
  }
}

.journey-Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.journey-heading-container {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  font-size: 36px;
  font-weight: 300;
  padding-top: 8%;
  padding-bottom: 5%;
}

.image-container > img {
  /*width: 80%;
  height: 80%;*/
  margin-bottom: 4%;
}

@media (max-width: 599px) {
  .journey-Container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .journey-heading-container {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    font-size: 28px;
    padding-top: 8%;
    padding-bottom: 5%;
  }

  .image-container > img {
    width: 90%;
    height: 100%;
    margin-bottom: 10%;
  }
}

.team-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}
.team-heading {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  opacity: 0.7;
  letter-spacing: 1px;
  font-size: 36px;
  font-weight: 300;
  padding-top: 8%;
}
.founder-description {
  display: flex;
  flex: 2 1;
  flex-direction: column;
  text-align: left;
  font-size: 15px;
  line-height: 2;
  color: black;
  opacity: 0.8;
  text-align: left;
}

.founder-socialMedia-icons-holder {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 25px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-transition: -webkit-transform 200ms;
  transition: -webkit-transform 200ms;
  transition: transform 200ms;
  transition: transform 200ms, -webkit-transform 200ms;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  display: flex;
  justify-content: center;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
.flip-card:hover + .coWorker-Details-front > .coWorker-name {
  display: none;
}
.flip-card:hover + .coWorker-Details-front > .coWorker-position {
  display: none;
}
.flip-card:hover
  + .coWorker-Details-front
  > .coWorker-socialMedia-icons-holder {
  display: none;
}
.coWorker-Details-front {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.flip-card-front {
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background: transparent;
  color: black;
  z-index: 2;
  display: block;
}

.flip-card-front-img {
  border-radius: 200px;
  height: 40%;
  width: 40%;
  border: 2px solid rgb(231, 204, 83);
}

.flip-card-front,
.flip-card-front > * {
  border-radius: 90%;
}
.flip-card-front,
.flip-card-back {
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
}
.coWorker-name {
  display: flex;
  flex: 0.5 1;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  padding-top: 0px;
  color: black;
}
.coWorker-position {
  display: flex;
  flex: 0.5 1;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #000000ad;
}
.coWorker-socialMedia-icons-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding-top: 2%;
}
.coWorkerDetails {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: normal;
  line-height: 2em;
  margin-bottom: 20px;
  text-align: left;
}

@media (min-width: 600px) {
  .founder-details-container {
    display: flex;
    flex: 0.6 1;
    flex-direction: row;
    margin: 90px;
    justify-content: center;
    align-items: center;
  }
  .founder-image-container {
    display: flex;
    flex: 1.9 1;
    flex-direction: column;
  }
  .founder-image > img {
    height: 45%;
    width: 45%;
  }
  .founder-name {
    display: flex;
    flex: 0.5 1;
    color: black;
    opacity: 0.87;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    padding-top: 20px;
  }
  .founder-position {
    display: flex;
    flex: 0.5 1;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    padding-top: 8px;
    color: black;
    opacity: 0.7;
  }
  .founder-description {
    margin-right: 10%;
    line-height: 1.8;
  }
  .socialMedia-icon {
    margin: 7px;
    color: #808080;
  }
  .team-coWorkers-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin: 90px;
    margin-top: 60px;
  }
  .team-coWorkers-subContainer {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    padding-top: 90px;
  }
  .team-coWorker {
    height: 100%;
    width: 100%;
  }
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 205px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .flip-card-back {
    width: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
    padding: 5%;
    text-align: justify;
    background-color: white;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    z-index: 1;
    position: absolute;
  }
}

@media (max-width: 599px) {
  .team-heading {
    font-size: 28px;
  }
  .founder-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .founder-image > img {
    height: 55%;
    width: 55%;
    margin-top: 10%;
  }
  .founder-name {
    display: flex;
    flex: 0.5 1;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: 500;
    padding-top: 20px;
    color: black;
    opacity: 0.8;
  }
  .founder-position {
    display: flex;
    flex: 0.5 1;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    padding-top: 12px;
    padding-bottom: 20px;
    color: black;
    opacity: 0.7;
  }
  .founder-description {
    margin: 5%;
    color: black;
    opacity: 0.8;
  }
  .socialMedia-icon {
    margin-right: 7px;
    color: #808080;
  }
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 200px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    margin-top: 10%;
  }
  .coWorker-Details-front {
    margin-bottom: 30%;
  }
  .flip-card-back {
    width: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    box-shadow: 5px 5px 8px -1px rgba(0, 0, 0, 0.47);
    padding: 5%;
    text-align: justify;
    color: white;
    background-color: white;
    border: 1px solid rgb(231, 204, 83);
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    z-index: inherit 999;
    position: absolute;
  }
}

.gallery-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(34, 29, 23);
  color: rgb(255, 255, 255);
}

.gallery-header-text-container {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-weight: 300;
  padding-top: 7%;
}
.gallery-image-video-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-evenly;
  margin: 5%;
}
.image-holder {
  width: 30%;
  height: 300px;
  border: 2px solid #ffd118;
  background-image: url(/static/media/IMG_0492.8695e665.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 2px;
}
.holder-button-background {
  background-color: #00000070;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.holder-button-background:hover {
  background-color: #000000b2;
}

.holder-button {
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  margin-bottom: 10%;
}

.video-holder {
  width: 30%;
  height: 300px;
  border: 2px solid #ffd118;
  background-image: url(/static/media/IMG_7827.f6b89058.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2px;
}
@media (max-width: 699px) {
  .gallery-image-video-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-evenly;
    align-items: center;
  }
  .gallery-header-text-container {
    font-size: 28px;
  }
  .image-holder {
    width: 80%;
    margin: 5%;
  }
  .video-holder {
    width: 80%;
    margin: 5%;
  }
}

* {
  margin: 0;
  padding: 0;
}
.services-container {
  background: whitesmoke;
  padding-top: 80px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-heading {
  font-size: 36px;
  color: black;
  font-weight: 300;
  letter-spacing: 0.04em;
  opacity: 0.7;
}
.services-sub-heading {
  font-size: 20px;
  line-height: 1.7;
  color: black;
  opacity: 0.6;
  padding: 2%;
  padding-bottom: 0;
}
.services-body-cotainer {
  width: 80%;
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}
.services-card-container {
  /* height: 400px; */
  width: 335px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
/* .services-cards-Paper {
  background-color: transparent;
  margin: 10px;
  margin-bottom: 50px;
  box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.14);
} */
.services-card-image-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.services-card-image-container {
  position: relative;
  height: 100px;
  width: 100%;
  padding-top: 5px;
  background-color: whitesmoke;
}
.services-card-text-container {
  align-items: flex-start;
  justify-content: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}

.slide-track,
.Partners-body-container {
  display: grid;
  grid-template-columns: 10em 10em 10em 10em;
}

.services-card-text-heading-container {
  height: 60px;
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.3px;
  opacity: 0.7;
  text-align: center;
}
.services-card-text-description-container {
  width: 80%;
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #868686;
  font-size: 14px;
  letter-spacing: 0.4px;
}
.services-card-button-container {
  height: 80px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.service-images-containers {
  top: 65;
  width: 180px;
  height: 180px;
  border-radius: 100px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 0px 20px #00000021;
  -webkit-box-shadow: 0px 0px 20px #00000021;
  z-index: 3000;
  background-color: white;
}
@media (max-width: 599px) {
  .services-heading {
    font-size: 28px;
  }
  .services-sub-heading {
    font-size: 18px;
    margin-top: 8%;
    line-height: 1.5em;
    text-align: center;
  }
  .services-card-text-heading-container {
    font-size: 17px;
  }
}
@media (min-width: 1800px) {
  .services-card-container {
    width: 450px;
  }
  .services-card-text-heading-container {
    font-size: 20px;
  }
  .services-card-text-description-container {
    font-size: 17px;
  }
}
.logo-holder {
  height: 150px;
}
.close-icon-holder {
  padding: 1%;
  font-size: 16px;
  color: #d8d5d5;
}
.close-icon-holder:hover {
  color: rgb(233, 30, 30);
}
.Transparent-blackLayer {
  background-color: rgba(17, 17, 17, 0.839);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
}
.modal-information-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
  flex: 1 1;
}
.modal-backgroundImage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  width: 85%;
  outline: none;
  background: url(/static/media/pexels-photo.fab34f7f.png) no-repeat center center fixed;
  background-size: cover;
}

.dialogRetiermentPlanBackgroundImage {
  background-image: url(/static/media/homepage_retirementplan.62f3997a.svg);
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yearSelector {
  color: black;
}

.dialogDreamCarBackgroundImage {
  background-image: url(/static/media/homepage_dreamcar.2871731f.svg);
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #ffff;
}
.dialogDreamHomeBackgroundImage {
  background-image: url(/static/media/homepage_home.6e3d7abb.svg);
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalAmountInput {
  outline: "none";
  height: "30px";
  border: "1.5px solid gray";
  border-radius: "5px";
  background-color: "rgba(0, 0, 0, 0)";
  width: "78%";
  color: "#c1bebe";
  text-align: "left";
  padding-left: "10px";
}
.dialogMarriagePlanBackgroundImage {
  background-image: url(/static/media/homepage_marriageplan.dfe1f864.svg);
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogEductionPlanBackgroundImage {
  background-image: url(/static/media/homepage_educationplan.e4f8d969.svg);
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogInvestmentPlanBackgroundImage {
  background-image: url(/static/media/homepage_investmentplan.22b0c0e0.svg);
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogGrowYourMoneyBackgroundImage {
  background-image: url(/static/media/homepage_moneygrowth.2601636f.svg);
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogIncomePlanBackgroundImage {
  background-image: url(/static/media/homepage_income.04c25b68.svg);
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogTravelPlanBackgroundImage {
  background-image: url(/static/media/homepage_travelplan.5b7dadd8.svg);
  background-color: rgba(0, 0, 0, 0.712);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogTransparentBackground {
  background-color: #000000f2;
  background-color: rgba(0, 0, 0, 0.94902);
  flex: 1 1;
  display: flex;
  height: 500px;
}
.dialog-body-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}
.dialog-cancle-icon-container {
  width: 97%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
}
.dialog-gturns-icon-container {
  height: 80px;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog-service-heading-container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  font-size: 21px;
  font-weight: 300;
  text-transform: uppercase;
}
.dialog-dream-car-period-selector {
  height: 100px;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.dialog-no-of-years-question-text-and-text-box-container {
  height: 120px;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 700px) {
  .dialog-dream-car-text-box-and-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 56%;
  }
  .dialog-dream-car-question-container {
    color: #b7b7b7;
    font-weight: 300;
    font-size: 15px;
    width: 47%;
    margin-right: 3%;
    text-align: center;
  }
  .selection-control {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .native-select {
    min-width: 263px;
    height: 38px;
    background: transparent;
    color: #a7a0a0;
    border-radius: 5px;
    border: 2px solid #a7a0a0;
    outline: none;
  }
  .amount-select {
    outline: none;
    height: 38px;
    border: 1.5px solid gray;
    border-radius: 5px;
    background-color: transparent;
    min-width: 263px;
    color: #c1bebe;
    text-align: left;
    padding-left: 10px;
  }
  .dialog-dream-car-investment-text-box-container {
    width: 56%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .error-message {
    color: red;
    font-size: 13px;
    padding-top: 8px;
    min-width: 263px;
    text-align: left;
    height: 15px;
  }
}
.dialog-dream-car-text-box-container {
  width: 65%;
}
.dialog-dream-car-investment-question-and-text-box-container {
  height: 120px;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialog-dream-car-next-back-buttons {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 699px) {
  .dialog-dream-car-question-container {
    color: #b7b7b7;
    font-weight: 300;
    font-size: 15px;
    height: 15px;
    width: 100%;
    text-align: center;
  }
  .native-select {
    min-width: 240px;
    height: 38px;
    background: transparent;
    color: #a7a0a0;
    border-radius: 5px;
    border: 2px solid #a7a0a0;
    outline: none;
  }
  .amount-select {
    outline: none;
    height: 38px;
    border: 1.5px solid gray;
    border-radius: 5px;
    background-color: transparent;
    min-width: 240px;
    color: #c1bebe;
    text-align: left;
    padding-left: 10px;
    outline: none;
  }
  .dialog-dream-car-period-selector {
    flex-direction: row;
    width: 100%;
    height: 60px;
  }
  .dialog-gturns-icon-container {
    height: 60px;
  }
  .dialog-service-heading-container {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    width: 90%;
  }
  .dialog-no-of-years-question-text-and-text-box-container {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .dialog-dream-car-text-box-and-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .dialog-dream-car-investment-question-and-text-box-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
  }
  .dialog-no-of-years-question-text-and-text-box-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .dialog-dream-car-investment-text-box-container {
    width: 85%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .dialog-dream-car-next-back-buttons {
    width: 100%;
    height: 60px;
  }
  .error-message {
    color: red;
    font-size: 10px;
    padding-top: 5px;
    width: 220px;
    height: 15px;
  }
}
.dialogBox ::-webkit-scrollbar {
  width: 0;
  display: none;
}
select option {
  background: #363636;
  border: 0px solid transparent;
}
select option[value] {
  color: #e4dede;
  font-size: 14px;
}

.certificaton-container {
  width: 100%;
  display: flex;
  height: 600px;
}
.certificate-background-image {
  background-image: url(/static/media/Gturns-aboutuspage-Logo_BG.caf726db.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto;
  height: 100%;
  width: 100%;
  background-color: black;
}
.certificate-black-background {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.856);
}
.certificate-carousel-heading {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-weight: 300;
  padding-top: 7%;
}
.certificate-image-carousel {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.certificate-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.certificate-slider-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.certificateslide {
  display: inline-block;
  height: 100%;
  width: 33%;
}
.certificate-image-holder {
  display: none;
}
.arrow {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: -webkit-transform ease-in 0.1s;
  transition: -webkit-transform ease-in 0.1s;
  transition: transform ease-in 0.1s;
  transition: transform ease-in 0.1s, -webkit-transform ease-in 0.1s;
}
.nextArrow {
  position: absolute;
  top: 50%;
  right: 25px;
  font-size: 40px;
  background: #0e0e0e6b;
}
.backArrow {
  position: absolute;
  top: 50%;
  left: 25px;
  font-size: 40px;
  background: #0e0e0e6b;
}
.fa-arrow-right:before,
.fa-arrow-left:before {
  color: #222;
}
.carousel-image-Dialog-Component {
  width: 699px;
  display: contents;
}
.MuiDialog-paperWidthMd,
.MuiDialog-paper {
  max-width: 480px;
}
@media (max-width: 799px) {
  .certificate-slider {
    display: none;
  }
  .certificaton-container {
    width: 100%;
    display: flex;
    height: 100%;
  }
  .certificate-image-holder-background {
    height: 100%;
    width: 100%;
    background-color: rgba(2, 2, 2, 0.94);
  }
  .certificate-background-image {
    background-image: url(/static/media/Gturns-aboutuspage-Logo_BG.caf726db.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    height: 100%;
    width: 100%;
    background-color: black;
  }
  .certificate-black-background {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.856);
  }
  .certificate-carousel-heading {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 28px;
    padding-top: 7%;
  }
  .certificate-image-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .certificate-modal-container {
    position: absolute;
    left: 15%;
    top: 10%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 80px;
    flex-direction: column;
    display: flex;
  }
  .certificate-heading {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    font-size: 38px;
    font-weight: 500;
    padding-top: 8%;
    padding-bottom: 8%;
  }
  .certificate-uttam-image,
  .certificate-lakshmi-image,
  .certificate-sharavan-image {
    width: 100%;
  }
}

.testimonials-container {
  width: 100%;
  background-color: rgba(26, 26, 26, 0.836);
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
}
.testimonial-card-container {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: center;
}
.card-container {
  height: 100%;
  width: 37%;
  background-color: transparent;
}
.card {
  height: 100%;
  width: 100%;
  color: rgb(241, 241, 241);
}
.testimonials-heading {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  letter-spacing: 1px;
  font-size: 36px;
  font-weight: 300;
  padding-top: 8%;
}
@media (min-width: 600px) {
  .testimonial-cardContainer {
    display: flex;
    flex-direction: column;
  }
  .testimonial-message-and-customer-name-container {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 860px) {
  .testimonial-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .testimonials-heading {
    font-size: 28px;
  }
}
@media (max-width: 599px) {
  .testimonial-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .testimonials-heading {
    font-size: 28px;
  }

  .card-container {
    height: 100%;
    width: 90%;
  }
  .testimonial-cardContainer {
    display: flex;
    flex-direction: column;
    /* height: 300px; */
  }
}

.contactInfo-container {
  width: 100%;
  background-color: rgb(26, 26, 26);
  color: rgb(255, 255, 255);
  padding-bottom: 30px;
  padding-top: 50px;
}

.contactInfo-subContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: rgba(204, 204, 204, 0.8);
  justify-content: center;
  align-items: center;
}

.companySlogan {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10%;
  padding-right: 0%;
}

.slogan-description {
  font-size: 15px;
  font-weight: 400;
  text-align: justify;
  padding-bottom: 40px;
  padding-right: 10px;
  line-height: 2em;
  letter-spacing: 0.3px;
}

.socialMedia-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding-top: 5%;
}

.icons-holder {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.twitter-icon {
  color: rgba(204, 204, 204, 0.8);
  font-size: 25px;
  margin: 7px;
}

.facebook-icon,
.linkedin-icon {
  color: rgba(204, 204, 204, 0.8);
  font-size: 25px;
  margin: 7px;
}

.socialMediaHeading {
  font-size: 15px;
  color: rgb(241, 241, 241);
  letter-spacing: 1px;
}

.contact-info-heading {
  font-size: 15px;
  color: rgb(241, 241, 241);
  padding-top: 5%;
}

.address-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.office-address {
  font-size: calc(7px + 1vmin);
  text-align: justify;
  padding-right: 10px;
}

.address-icon-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  padding-right: 7px;
}

.formContainers {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding-left: 10%;
  height: 100%;
}

.emailSubscription {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: flex-start;
}

.dropMessage {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 10%;
}

.input-container-one {
  flex-direction: row;
  display: flex;
  padding-top: 4%;
}

::-webkit-input-placeholder {
  font-weight: 400;
  color: rgb(200, 197, 197);
  font-size: 13px;
}

::-ms-input-placeholder {
  font-weight: 400;
  color: rgb(200, 197, 197);
  font-size: 13px;
}

::placeholder {
  font-weight: 400;
  color: rgb(200, 197, 197);
  font-size: 13px;
}

.contactInfo-anchor-container {
  width: 85%;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  padding-top: 20%;
}

.contactInfo-anchor {
  font-size: 12px;
  color: rgba(175, 174, 174, 0.8);
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  font-weight: 500;
  border-right: 0.7px solid rgba(156, 152, 152, 0.8);
}

a:nth-last-of-type(1) {
  border-right: none;
}

.contactInfo-gturns-logo {
  width: 25%;
  align-items: flex-start;
  justify-content: left;
  display: flex;
  margin-bottom: 10%;
}

@media (max-width: 599px) {
  .contactInfo-companySlogan-container {
    height: 100%;
    width: 90%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  .formContainers {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding-left: 10%;
    height: 100%;
  }

  .emailSubscription {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 6%;
  }

  .companySlogan {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding-left: 3;
    padding-right: 3%;
  }
}

@media (min-width: 861px) {
  .contactInfo-companySlogan-container {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex: 1 1;
  }
}

@media (max-width: 860px) {
  .contactInfo-companySlogan-container {
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
}

.map-container {
  width: 100%;
  background-color: rgb(33, 33, 33);
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
.grid-container {
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 5%;
}
.contactus-heading {
  font-size: 36px;
  font-weight: 300;
  color: white;
}
.map-card-container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding-top: 15px;
}
.card-image-container {
  height: 160px;
  min-width: 306px;
  background-color: rgb(241, 241, 241);
}
.map-card-header {
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 10px;
  letter-spacing: 0.6px;
  font-weight: 300;
}
.card-info-container {
  min-height: 170px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  padding-top: 0;
}
.card-info-description {
  font-size: 14px;
  text-align: left;
  color: rgb(173, 173, 173);
  margin-top: 15px;
  line-height: 1.5em;
}
.phone-no-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.card-deails-sub-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  /* padding-top: 10px; */
}
.map-link-anchor {
  color: rgb(231, 204, 83);
  font-size: 12px;
  text-decoration: none;
  border-bottom: 1px solid rgb(231, 204, 83);
  margin-top: 25px;
}
.business-proposal-sub-header {
  color: rgb(230, 227, 227);
  font-size: 17px;
  padding-top: 10px;
}
@media (min-width: 600px) {
  .grid-container {
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 599px) {
  .grid-container {
    display: flex;
    flex-direction: column;
  }
  .contactus-heading {
    font-size: 28px;
  }
}

.FooterContainer {
  min-height: 56px;
  background-color: rgb(0, 0, 0);
  color: rgba(204, 204, 204, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.footer-anchor-container {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  margin-left: 10%;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
}
.footer-copyrights-container {
  display: flex;
  flex-direction: row;
  flex: 0.8 1;
  margin-left: 27%;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.03em;
}
.footer-anchor {
  font-size: 13px;
  color: rgba(204, 204, 204, 0.8);
  padding-right: 10px;
  text-decoration: none;
  font-weight: 200;
  letter-spacing: 0.03em;
}
.footer-anchor-sub {
  font-size: 13px;
  color: rgba(204, 204, 204, 0.8);
  padding-right: 10px;
  text-decoration: none;
  font-weight: 300;
  letter-spacing: 0.03em;
}
.footer-anchor:hover {
  cursor: pointer;
}
.fotter-privacy-policy-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.fotter-privacy-policy-Heading-container {
  color: white;
  font-size: 25px;
  margin-left: 20px;
}
.fotter-privicy-policy-body-container {
  padding: 50px;
}
.fotter-privicy-policy-content-header-container {
  font-size: 18px;
  font-weight: 500;
  margin-top: 2%;
  letter-spacing: 0.5px;
}
.privicy-policy-content-container {
  margin-top: 1%;
  line-height: 2;
  letter-spacing: 0.2px;
}
.fotter-unOrdered-list-class {
  margin-left: 1%;
}
@media (max-width: 599px) {
  .footer-copyrights-container {
    display: flex;
    flex-direction: row;
    flex: 0.8 1;
    margin-left: 0%;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.03em;
    align-items: center;
  }
  .footer-anchor-container {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    margin-left: 0%;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
  }
  .FooterContainer {
    display: flex;
    flex-direction: column;
    height: 85px;
  }
  .fotter-p-classes {
    margin-top: 2%;
  }
  .fotter-privicy-policy-body-container {
    padding: 20px;
  }
  .fotter-privicy-policy-content-header-container {
    margin-top: 5%;
    letter-spacing: 0.5px;
  }
  .privicy-policy-content-container {
    margin-top: 5%;
    line-height: 1.5;
    letter-spacing: 0.3px;
  }
}

@media (min-width: 800px) {
  .Header-Container {
    height: 80px;
    width: 100%;
    position: relative;
    z-index: 900;
  }
  .Header {
    align-items: center;
    justify-content: center;
    background: transparent;
    min-height: 80px;
    text-align: center;
    flex-direction: row;
    display: flex;
    z-index: 9000;
    color: rgb(228, 226, 226);
  }
  .Content {
    padding: 16px;
  }
  .Sticky {
    position: fixed;
    color: rgb(241, 241, 241);
    box-shadow: 0 8px 6px -6px rgb(44, 38, 38);
    top: 0;
    width: 100%;
    -webkit-animation-name: headerBar;
            animation-name: headerBar;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    -webkit-animation-delay: 300ms;
            animation-delay: 300ms;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .Sticky + .Content {
    padding-top: 102px;
  }
  @-webkit-keyframes headerBar {
    from {
      top: -80px;
      background-color: rgb(85, 85, 85);
    }
    to {
      top: 0px;
      background-color: black;
    }
  }
  @keyframes headerBar {
    from {
      top: -80px;
      background-color: rgb(85, 85, 85);
    }
    to {
      top: 0px;
      background-color: black;
    }
  }
  .header-logo-container {
    flex: 0.6 1;
    display: flex;
    padding-left: 5%;
    align-items: center;
  }

  .header-logo {
    width: 55px;
    margin-top: 1%;
  }
  .header-logo:hover {
    cursor: pointer;
  }
  .header-logo-hover-changer {
    text-decoration: none;
    cursor: default;
  }
  .header-logo-hover-changer .header-company-name {
    display: none;
  }
  /* .header-logo-hover-changer:hover .header-logo {
    display: none;
  }
  .header-logo-hover-changer:hover .header-company-name {
    display: inline;
    animation-name: companyName;
    animation-duration: 500ms;
    animation-delay: 100ms;
    animation-fill-mode: both;
    color: rgb(231, 204, 83);
  } */
  .header-anchor-container {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 3%;
  }
  .header-anchor-container a {
    display: flex;
    flex-direction: row;
    float: left;
    text-align: center;
    padding: 20px;
    padding-bottom: 7px;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 0.5px;
    border-bottom: 2px solid transparent;
    color: rgba(204, 204, 204, 0.8);
    white-space: nowrap;
  }
  .header-anchor-container a:hover {
    border-bottom: 1px solid rgb(231, 204, 83);
    color: rgb(255, 255, 255);
  }
  .header-anchor-container a#active {
    border-bottom: 1px solid rgb(231, 204, 83);
    color: rgb(255, 255, 255);
  }
  .header-getStartedButton {
    height: 40px;
    width: 23%;
    background-color: #ffd118;
    border: none;
    color: #070707e3;
    border-radius: 3px;
    margin-top: 10px;
    margin-left: 3%;
    font-size: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
  }
  .icon-holder {
    display: none;
    flex-direction: column;
    flex: 1 1;
  }
  .anchors-holder {
    display: flex;
    flex-direction: row;
    flex: 1 1;
  }
  .getStarted-Dialog-Component {
    height: 500px;
    width: 699px;
  }
}

@media (max-width: 799px) {
  .Header-Container {
    height: 80px;
    width: 100%;
    z-index: 900;
  }

  .Header {
    align-items: center;
    justify-content: center;
    background: transparent;
    min-height: 80px;
    text-align: center;
    flex-direction: row;
    display: flex;
    z-index: 9000;
    color: white;
  }
  .swipeableDrawer > * {
    position: relative;
    top: 80px;
  }
  .swipeableMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
  }
  .header-logo-container {
    flex: 0.6 1;
    display: flex;
    align-items: center;
    margin-left: 5%;
  }
  .header-logo {
    height: 45px;
    margin-top: 1%;
  }
  .header-logo-hover-changer .header-company-name {
    display: none;
  }
  /* .header-logo-hover-changer:hover .header-logo {
    display: none;
  } */
  /* .header-logo-hover-changer:hover .header-company-name {
    display: inline;
    animation-name: companyName;
    animation-duration: 500ms;
    animation-delay: 100ms;
    animation-fill-mode: both;
    color: rgb(231, 204, 83);
  } */
  .header-anchor-container {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    padding-bottom: 10px;
  }
  .header-anchor-container a {
    display: flex;
    flex-direction: column;
    padding-bottom: 7px;
    margin: 20px;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    border-bottom: 2px solid transparent;
    color: rgba(204, 204, 204, 0.8);
  }
  .header-anchor-container a:hover {
    border-bottom: 1px solid rgb(231, 204, 83);
  }
  .header-anchor-container a#active {
    border-bottom: 1px solid rgb(231, 204, 83);
    color: rgb(255, 255, 255);
  }
  .header-getStartedButton {
    height: 40px;
    width: 35%;
    background-color: #ffd118;
    border: none;
    color: black;
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }
  .Content {
    padding: 16px;
  }
  .Sticky {
    position: fixed;
    color: rgb(241, 241, 241);
    box-shadow: 0 8px 6px -6px rgb(44, 38, 38);
    top: 0;
    width: 100%;
    -webkit-animation-name: headerBar;
            animation-name: headerBar;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-delay: 500ms;
            animation-delay: 500ms;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .Sticky + .Content {
    padding-top: 102px;
  }
  @-webkit-keyframes headerBar {
    from {
      top: -80px;
      background-color: rgb(85, 85, 85);
    }
    to {
      top: 0px;
      background-color: black;
    }
  }
  @keyframes headerBar {
    from {
      top: -80px;
      background-color: rgb(85, 85, 85);
    }
    to {
      top: 0px;
      background-color: black;
    }
  }
  .icon-holder {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding-right: 10%;
  }

  .anchors-holder {
    display: none;
    flex-direction: row;
    flex: 1 1;
  }
  .getStarted-Dialog-Component {
    margin: 0%;
  }
}

.getStarted-dilog-main-container {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.getStarted-dilog-cancle-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-right: 15px;
}
.getStarted-dialog-body-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.getStarted-dialog-header-text-image-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 140px;
  /* border: 0.5px solid gray; */
  margin-top: 1%;
}
@media (min-width: 800px) {
  .getStarted-dialog-header-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 35px;
  }
  .getStarted-dialog-header-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 35px;
  }
  .getStarted-dialog-header-image {
    width: 60%;
  }
  .getStarted-dialog-name-container {
    width: 100%;
    padding-left: 35px;
    flex-direction: column;
  }
  .getStarted-dialog-email-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 35px;
    flex-direction: column;
    align-items: flex-end;
  }
  .getStarted-dialog-phone-container {
    width: 40%;
    padding-left: 35px;
  }
  .getStarted-dialog-dmatAccount-container {
    width: 60%;
    padding-right: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .getStarted-dialog-message-container {
    margin-left: 35px;
    margin-right: 35px;
    height: 80px;
  }
  .getStarted-dialog-dmatAccount-selector {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .question-checkbox-continer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }
}
.getStarted-header-text-heading {
  font-size: 21px;
  font-weight: 400;
}
.getStarted-header-text-description {
  font-size: 11px;
  font-weight: 400;
  margin-top: 4%;
  color: rgb(110, 109, 109);
  line-height: 1.7;
}
.getStarted-dialog-textFields-container {
  width: 100%;
  /* border: 0.5px solid red; */
  flex-direction: column;
}
.getStarted-dialog-send-container {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  /* border: 0.5px solid lightgreen; */
}
.getStarted-dialog-name-email-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* border: 0.5px solid blue; */
}

.getStarted-dialog-phone-dmatAccount-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 3%;
}

.getStarted-dialog-dmatAccount-question {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  font-size: 16px;
  color: rgb(110, 109, 109);
  font-weight: 400;
}

.errorGetStartedContainers {
  width: 95%;
  color: red;
  font-size: 11px;
  height: 14px;
  font-weight: 500;
  margin-top: 5px;
}
.getStarted-dialog-thank-you-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.getStarted-dialog-check-icon {
  margin-top: 10%;
  height: 130px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffd118;
  border-radius: 100px;
  margin-bottom: 5%;
}
.getStarted-dialog-thank-you-text {
  font-size: 25px;
  font-weight: 400;
}
.getStarted-dialog-thank-you-description {
  font-size: 11px;
  font-weight: 500;
  margin-top: 2%;
  color: rgb(110, 109, 109);
  line-height: 1.7;
}
@media (max-width: 799px) {
  .getStarted-dialog-header-text-image-container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .getStarted-dialog-header-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .getStarted-dialog-header-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
  }
  .getStarted-dialog-header-image {
    width: 40%;
    margin-top: 2%;
  }
  .getStarted-header-text-description {
    text-align: center;
  }
  .getStarted-dialog-name-email-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .getStarted-dialog-name-container {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .getStarted-dialog-email-container {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .getStarted-dialog-phone-dmatAccount-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3%;
    justify-content: center;
    align-items: center;
  }
  .getStarted-dialog-phone-container {
    width: 86%;
    display: flex;
    flex-direction: column;
  }
  .getStarted-dialog-dmatAccount-question {
    display: flex;
    width: 100%;
    height: 55px;
    justify-content: center;
    align-items: flex-end;
    font-size: 15px;
    font-weight: 400;
  }
  .getStarted-dialog-dmatAccount-selector {
    width: 100%;
    margin-top: 2%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .getStarted-dialog-dmatAccount-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .getStarted-dialog-send-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .getStarted-dialog-textFields-container {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .getStarted-dialog-message-container {
    width: 90%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .getStarted-dialog-thank-you-message {
    width: 350px;
  }
}

.calculator_Whole_page {
  height: 100%;
}

.calculatorPage {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: hsl(0, 0%, 100%);
}

.calc-Header {
  flex: 0.25 1;
  display: flex;
  background-color: #32373c;
}

.calc-Body {
  flex: 3.5 1;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
}

.calc-Footer {
  flex: 0.4 1;
  display: flex;
  background-color: #f5f4f4c9;
  border-top: 1px solid #e3ddd8fc;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.5em 0em 1.5em 0em;
  font-size: 0.78em;
  letter-spacing: 0.02em;
  color: #504e4e;
}

.calc-HeaderContainer {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  padding: 0.7em;
}

.calc-Lable {
  flex: 1 1;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  color: white;
  font-size: 1em;
  font-weight: 600;
}
.calc-Close {
  flex: 1 1;
  text-align: right;
  color: white;
  font-size: 2em;
}
#calc-cancelIcon {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
}
#calc-cancelIcon:hover {
  cursor: pointer;
}

.calc-InputContainer {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  padding: 2em;
}
.calc-ResultContainer {
  flex: 1 1;
  display: flex;
}

.calc-Periods {
  padding: 0em 0em 0.4em 0em;
  font-size: 0.9em;
  letter-spacing: 0.02em;
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.calc-Past {
  padding-right: 8em;
}

#calc-RadioGroup {
  flex-direction: row;
}

.calc-ScripSelector {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
  margin: 1em 0em;
  letter-spacing: 0.02em;
}

.calc-Selector {
  width: 100%;
  font-size: 0.85em;
  letter-spacing: 0.02em;
}
#calc-ScripSelectorText {
  font-size: 0.85em;
  letter-spacing: 0.02em;
  height: 0.9em;
}
#calc-MenuItem {
  font-size: 0.85em;
  letter-spacing: 0.02em;
  color: #504e4e;
}
.calc-InvestedLable {
  display: flex;
  padding: 2em 0em 1em 0em;
  font-size: 0.85em;
  letter-spacing: 0.02em;
  color: #504e4e;
}

.calc-Text {
  flex: 1 1;
  outline: #ffe600;
  width: 100%;
  color: #504e4e;
}

.calc-ScripsLable {
  display: flex;
  padding: 2em 0em 1em 0em;
  color: #504e4e;
}

.calc-InvestedAmount {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.calc-YearSlider {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
  letter-spacing: 0.02em;
}

.calc-YearList {
  display: flex;
  flex-direction: row;
  color: #504e4e;
  justify-content: space-between;
  flex: 1 1;
  cursor: pointer;
}
.calc-Year {
  padding-right: 1em;
}
.calc-YearLable {
  display: flex;
  padding: 2em 0em 1.2em 0em;
  color: #504e4e;
}

.calc-SlideSelector {
  display: flex;
  padding: 2em 0em 1em 0em;
}

.calc-Slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.calc-Slider:hover {
  opacity: 1;
}

.calc-Slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: radial-gradient(rgb(250, 250, 250), #ffe600, #ffbb00);
  cursor: pointer;
  border-radius: 50%;
}

.calc-ButtonContainer {
  flex: 0.7 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 1em;
}

.calc-Button button {
  background-color: #ffe600;
  font-size: 0.9em;
  color: black;
  font-weight: 500;
}

.calc-Button button:hover {
  background-color: #ffe600;
}

.calc-ResultData {
  display: flex;
  flex-direction: column;
  height: 15em;
  width: 100%;
  position: relative;
  left: 20%;
  margin-top: 2em;
  border: 1px solid rgb(252, 245, 245);
  background-color: white;
  box-shadow: -11px 13px 21px -12px rgba(194, 192, 192, 1);
  border-radius: 1px;
}

.calc-ResultHeader {
  display: flex;
  font-size: 1.1em;
  letter-spacing: 0.02em;
  font-weight: 600;
  padding: 1em 1em 1em 0em;
  border-bottom: 1px solid rgb(234, 235, 235);
}

.calc-ResultBody {
  display: flex;
  flex-direction: row;
}
.calc-ResultLable {
  flex: 1 1;
  font-size: 1em;
  letter-spacing: 0.02em;
  color: #504e4e;
}
.calc-ResultValue {
  color: #000;
  font-size: 1em;
  letter-spacing: 0.02em;
  text-align: right;
  font-weight: 900;
}

.calc-ResultReturn,
.calc-ReturnValue {
  padding-top: 1em;
}

@media (max-width: 454px) {
  .calc-Past,
  .calc-Future {
    flex: 1 1;
    padding: 0;
  }
  .calc-Year,
  .calc-LastYear {
    padding-right: 0;
    flex: 1 1;
    font-size: 0.8em;
  }
}

@media (max-width: 330px) {
  .calc-InputContainer {
    padding: 1em;
  }
}

@media (max-width: 874px) {
  .calculator_Whole_page {
    padding: 0;
    align-items: normal;
    justify-content: normal;
  }
  .calculatorPage {
    width: 100%;
  }
  .calc-Header {
    /* min-height: 46.38px; */
  }
  .calc-Body {
    flex-direction: column;
    /* min-height: 749.36px; */
  }
  .calc-ResultData {
    left: 0em;
    margin: 0em;
  }

  .calc-ResultContainer {
    justify-content: center;
    padding: 1em;
  }
  .calc-Year {
    padding-right: 0;
    flex: 1 1;
  }
  .calc-Footer {
    /* padding: 1em 1em 1em 1em; */
    /* min-height: 64.44px; */
  }
}

#radio .ff {
  visibility: hidden;
}

.faq-main-container {
  width: 100%;
  /* height: px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.faq-header-container {
  width: 100%;
  /* height: 200px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fafafa;
  font-size: 45px;
  font-weight: 300;
}
.faq-header-description-container {
  font-size: 15px;
  color: #d7dce2d9;
  line-height: 2em;
  text-align: justify;
}
.faq-body-container {
  width: 85%;
  flex: 1 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  text-align: initial;
  padding-top: 5%;
}
.faq-expansion-panel {
  width: 100%;
  border-radius: 0px;
}
.faq-typography-style {
  color: white;
}
.faq-panel-lower-border {
  height: 1px;
  background: #a090908c;
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.faq-heading-text-image-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: transparent;
  margin-top: 6%;
  margin-bottom: 4%;
}
.faq-heading-text-container {
  width: 45%;
  text-align: left;
  text-align: initial;
  justify-content: space-around;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  padding: 15px;
  margin-left: 4%;
  font-size: 45px;
  font-weight: 300;
  /* color: #f1f1f1; */
  color: white;
}
.faq-heading-image-container {
  width: 55%;
  /* height: 100%; */
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid red;
}
.faq-questions-container {
  background-color: white;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.faq-heading-text-image-container > img {
  height: 50%;
}
.faq-answer-image > img {
  width: "90%";
}
.faq-search-box-container{
width:60%;
margin-top: 40px;  
margin-bottom:40px;
display: flex;
justify-content: center;
align-items: center;
box-shadow: 0px 0px 9px #00000040;
border-radius: 40px;
background: white;
}
.faq-search-box-container:hover{
  box-shadow: 0px 0px 9px #0000006a;
  /* border: 3px solid rgb(255, 209, 24); */
  }
@media (max-width: 599px) {
  .faq-heading-text-image-container {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    background: transparent;
    padding-top: 10%;
  }
  .faq-header-container {
    font-size: 28px;
    font-weight: 300;
  }
  .faq-heading-text-container {
    font-weight: normal;
    width: 85%;
    text-align: justify;
    justify-content: space-around;
    padding: 15px;
    font-size: 28px;
    font-weight: 500;
    color: white;
    margin-left: 0%;
  }
  .faq-heading-image-container {
    width: 80%;
    /* height: 100%; */
    justify-content: center;
    display: flex;
    align-items: center;
    padding-bottom: 6%;
  }
  .faq-questions-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 7%;
  }
  .faq-heading-image-container {
    width: 100%;
  }
  .faq-heading-text-image-container > img {
    height: 250px;
  }
  .faq-answer-image > img {
    width: 250;
  }
  .faq-search-box-container{
    width:80%;  
    margin-bottom: 40px;
    }
}
@media (min-width: 1800px) {
  .faq-header-description-container {
    font-size: 25px;
  }
  .faq-heading-text-container {
    font-size: 60px;
  }
  .faq-header-container {
    font-size: 45px;
  }
}

.pagination-main-container {
  margin-top: 30px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
}
.pagination-buttons-container {
  display: flex;
  width: 97%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.Homepage-container {
  background-color: rgba(14, 14, 14, 0.88);
  padding-top: 3%;
  flex: 1 1;
  color: rgb(250, 250, 250);
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

@media (min-width: 600px) {
  .Homepage-banner1 {
    height: 650px;
    width: 100%;
  }
  .background-image-contain {
    display: flex;
    width: 100%;
    margin-top: 10%;
    flex-direction: column;
  }
}
@media (max-width: 599px) {
  .Homepage-banner1 {
    height: 398px;
    width: 100%;
  }
  .background-image-contain {
    display: flex;
    width: 100%;
    margin-top: 65px;
    flex-direction: column;
  }
}
.Homepage-heading {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 80%;
  border-radius: 5px;
  background-color: #6563631f;
  box-shadow: 0px 0px 30px #000;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Homepage-heading-text-title {
  margin-top: 8%;
  font-size: 45px;
  font-weight: 300;
  letter-spacing: 0.8px;
}
.Homepage-heading-text-description {
  margin-top: 6%;
  font-size: 15px;
  width: 100%;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  text-align: center;
  color: #d7dce2d9;
}
.Homepage-heading-icons {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #e8e8e863;
}
.dot-icon:hover {
  color: white;
}
.bottom-tab-container {
  height: 100px;
  display: flex;
  background: rgba(0, 0, 0, 0.41);
  width: 100%;
  justify-content: center;
  align-items: center;
}
.svg-text-container {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.rupee-icon-text {
  border: 0px solid red;
  text-align: left;
  padding: 20;
  padding-top: 10;
  letter-spacing: 0.3px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7;
}
@media (max-width: 599px) {
  .Homepage-container {
    /* background-color: rgba(14, 14, 14, 0.95); */
    padding-top: 15%;
    color: rgb(250, 250, 250);
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .Homepage-heading {
    width: 90%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .Homepage-heading-text-title {
    font-size: 28px;
    font-weight: 500;
  }
  .Homepage-heading-text-description {
    margin-top: 7%;
    font-size: 13px;
  }
  .Homepage-heading-icons {
    margin-top: 15%;
    margin-bottom: 10%;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .bottom-tab-container {
    height: 200px;
  }
  .svg-text-container {
    flex-direction: column;
  }
  .rupee-icon-text {
    border: 0px solid red;
    text-align: center;
    padding: 20;
    padding-top: 10;
    font-size: 12px;
    letter-spacing: 0.3px;
    font-weight: 300;
    line-height: 1.7;
  }
}
@media (min-width: 1880px) {
  .Homepage-heading {
    width: 72%;
  }
  .Homepage-heading-text-title {
    font-size: 60px;
  }
  .Homepage-heading-text-description {
    font-size: 30px;
  }
}

/*********************** Slide show Styles ***************/
* {
  box-sizing: border-box;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
.mySlides {
  display: none;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1500px;
  position: relative;
  margin: auto;
  min-width: 1080px;
}

/* Next & previous buttons */
.prev {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  left: 0;
}
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  right: 0;
}
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}

@media (min-width: 800px) {
  /* width */
  .banner-backgroundImage::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  .banner-backgroundImage::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .banner-backgroundImage::-webkit-scrollbar-thumb {
    background: rgb(119, 115, 115);
    border-radius: 10px;
  }

  /* Handle on hover */
  .banner-backgroundImage::-webkit-scrollbar-thumb:hover {
    background: #ffd118;
  }
}
.banner-backgroundImage {
  overflow-y: hidden;
}
.market-analysis-image {
  top: -40px;
}
.data-driven-image {
  top: 82px;
}
@media (max-width: 599px) {
  .banner-backgroundImage {
    overflow-y: auto;
  }
  .market-analysis-image {
    top: -40px;
  }
  .data-driven-image {
    top: 89px;
  }
}

.blog-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #f7f7f7;
}
.blog-main-header-container {
  height: 90px;
  width: 100%;
  background-color: black;
  box-shadow: 0 8px 6px -6px rgb(44, 38, 38);
}
.blog-body-container {
  margin-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* border: 1px solid blue; */
}
.blog-body-contents-container {
  /* margin-top: 5%; */
  width: 80%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid blue; */
}
.blog-header-container {
  width: 100%;
  /* border: 1px solid black; */
}
.blog-header-text-container {
  font-size: 36px;
  font-weight: 300;
  display: flex;
  text-align: left;
  color: black;
  opacity: 0.7;
}
.blog-name-date-like-view-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* margin-top: 2%; */
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.8;
  text-align: left;
}
.blog-body-text-1-container {
  margin-top: 5%;
  color: #464141;
  font-size: 18px;
  text-align: justify;
  letter-spacing: 0.2px;
  line-height: 3em;
  line-height: 1.8;
  text-align: left;
}
.blog-body-text-2-container,
.blog-body-text-3-container {
  margin: 2% 0;
  color: #464141;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 2em;
  text-align: left;
  white-space: wrap;
}
.blog-body-text-4-container {
  margin-top: 10%;
  color: #464141;
  font-size: 15px;
  text-align: justify;
  letter-spacing: 0.2px;
  line-height: 1.5em;
}
.blog-name-date-container {
  display: flex;
  flex-direction: row;
}
.blog-likes-view-container {
  display: flex;
  flex-direction: row;
}
.blog-name-container {
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.blog-date-container {
  color: gray;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.blog-likes-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray;
  padding: 15px;
}
.blog-views-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray;
  padding: 15px;
}

@media (min-width: 600px) {
  .blog-body-text-1-container,
  .blog-body-text-2-container > p {
    margin-top: 1em;
    margin-bottom: 0.8em;
    text-align: justify;
    font-size: 16px;
    line-height: 1.8;
  }
  .blog-body-text-1-container,
  .blog-body-text-2-container > ul {
    margin: 1em 3em;
    font-size: 16px;
    text-align: justify;
    line-height: 1.8;
  }
  .divider-next-pervious {
    display: flex;
    justify-content: space-between;
    width: 85%;
  }
}
@media (max-width: 599px) {
  .divider-next-pervious {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .blog-body-text-1-container,
  .blog-body-text-2-container > p {
    margin-top: 1em;
    margin-bottom: 0.8em;
    text-align: left;
    font-size: 16px;
    line-height: 1.8;
  }
  .blog-body-text-1-container,
  .blog-body-text-2-container > ul {
    /* margin: 0; */
    margin: 1em 2em;
    text-align: left;
    font-size: 16px;
    line-height: 1.8;
  }

  .blog-header-text-container {
    font-size: 28px;
    letter-spacing: 0.2px;
    opacity: 0.7;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: left;
    color: black;
  }
  .blog-name-date-like-view-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* margin-top: 2%; */
    text-align: justify;
    justify-content: space-between;
    align-items: center;
    font-size: calc(8px + 1vmin);
  }
  .likes,
  .views {
    display: none;
  }
}
@media (min-width: 1450px) {
  .blog-main-header-container {
    /* height: 80px; */
    width: 100%;
    background-color: black;
  }
}
/* 
.blog-body-text-1-container,
.blog-body-text-2-container > p {
  margin-top: 1em;
  margin-bottom: 0.8em;
  text-align: justify;
  font-size: 16px;
} */
/* .blog-body-text-1-container,
.blog-body-text-2-container > br {
  visibility: hidden;
} */

strong {
  text-transform: capitalize;
}
.blog-body-text-1-container,
.blog-body-text-2-container > a {
  white-space: pre-wrap;
  font-size: 14px;
  opacity: 0.7;
  line-height: 1.5;
  text-align: center;
}
/* blockquote {
  margin: 3em 0;
} */
/* li {
  margin: 3em 0;
} */

#previous-container-active {
  margin-right: 30px;
  display: flex;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: gray;
  outline: none;
}
#next-container-active {
  display: flex;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: gray;
  outline: none;
}
#next-container-active:hover,
#previous-container-active:hover {
  color: rgb(255, 209, 24);
  cursor: pointer;
}
#previous-container-inActive {
  margin-right: 30px;
  display: flex;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #808080ad;
  outline: none;
  cursor: "auto";
}
#next-container-inActive {
  display: flex;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #808080ad;
  outline: none;
  cursor: auto;
}

.blog-image-container {
  width: 100%;
  margin-top: 5%;
}
.blog-image {
  width: 100%;
}
@media (max-width: 599px) {
  .blog-image {
    width: 100%;
  }
}

.blog-social-media-container {
  width: 10%;
  margin: 16% 0 5% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 230px;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  
  /* border: 1px solid red; */
}

.blog-social-media-linkedIn {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  font-size: calc(20px + 1vmin);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #137db7;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.blog-social-media-twitter {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  font-size: calc(20px + 1vmin);
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #2ca9df;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.blog-social-media-facebook {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  font-size: calc(20px + 1vmin);
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #4e6fa7;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.blog-social-media-whatsapp {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  font-size: calc(20px + 1vmin);
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #2fbd39;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.blog-social-media-whatsapp:hover,
.blog-social-media-facebook:hover,
.blog-social-media-linkedIn:hover,
.blog-social-media-twitter:hover {
  cursor: pointer;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  z-index: 1;
}
@media (max-width: 599px) {
  .blog-social-media-linkedIn,
  .blog-social-media-whatsapp,
  .blog-social-media-facebook,
  .blog-social-media-twitter {
    height: 25px;
    width: 25px;
    font-size: calc(5px + 1vmin);
  }
  .blog-social-media-container {
    height: 160px;
    margin-top: 50%;
  }
}

.blog-YMAL-container {
  padding: 0em 1em;
}
.blog-YMAL-heading-container {
  margin: 1.5em 0;
  font-weight: normal;
  font-size: 2em;
  font-weight: 300;
  color: hsla(0, 0%, 20%, 0.859);
  text-align: center;
}
.blog-YMAL-body-container {
  width: 100%;
  margin-top: 3%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 15%;
}
.blog-YMAL-links-container-odd {
  min-width: 380px;
  flex-direction: row;
  display: flex;
  height: 120px;
  /* background-color: white; */
  margin: 2%;
  padding: 15px;
  color: rgb(88, 88, 88);
  justify-content: space-between;
  border-radius: 5px;
  /* box-shadow: 0px 0px 20px #00000021; */
}
.blog-YMAL-links-container {
  max-width: 380px;
  flex-direction: row;
  display: flex;
  height: 120px;
  background-color: white;
  margin: 2%;
  padding: 15px;
  color: rgb(88, 88, 88);
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #00000021;
}
.blog-YMAL-links-image-container {
  width: 30%;
  /* border: 1px solid black; */
}
.blog-YMAL-links-text-container {
  width: 65%;
  /* border: 1px solid black; */
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}
.blog-YMAL-links-Date-Read-more-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.blog-YMAL-links-Name-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #bfbcbc;
}
.blog-YMAL-links-Date-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #bfbcbc;
}
.blog-YMAL-links-Read-more-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #ffd118;
}
.blog-YMAL-links-heading-name-container {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}
@media (max-width: 599px) {
  .blog-YMAL-body-container {
    width: 100%;
    margin-top: 10%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .blog-YMAL-links-container-odd {
    display: none;
  }
  .blog-YMAL-links-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    height: 125px;
    background-color: white;
    justify-content: space-between;
  }
  .blog-YMAL-heading-container {
    margin-top: 10%;
    font-weight: normal;
    font-size: calc(30px + 1.4vmin);
    color: hsla(0, 0%, 20%, 0.859);
  }
}
@media (min-width: 1400px) {
  .blog-YMAL-links-container {
    width: 420px;
    flex-direction: row;
    display: flex;
    height: 140px;
    background-color: white;
    margin: 2%;
    padding: 15px;
    justify-content: space-between;
    border-radius: 2px;
    box-shadow: 0px 0px 20px #00000021;
  }
  .blog-YMAL-links-heading-name-container {
    font-size: 18px;
    text-align: left;
  }
  .blog-YMAL-links-Name-container {
    font-size: 15px;
  }
  .blog-YMAL-links-Date-container {
    font-size: 15px;
  }
  .blog-YMAL-links-Read-more-container {
    font-size: 15px;
  }
}

.blogsList-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.blogsListHeaderBackground {
  height: 90px;
  background-color: black;
  box-shadow: 0 8px 6px -6px rgb(44, 38, 38);
}
.blogsList-body-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #f7f7f7;
  /* border: 0.5px solid coral; */
}
.blogsList-body-content-container {
  width: 80%;
}
.temp-blog-Data {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.temp-blog-container {
  display: flex;
  flex-direction: column;
}
.temp-blog-header {
  text-align: left;
  font-size: 2em;
  font-weight: 300;
  opacity: 0.7;
  padding: 10px 0px;
}
.temp-blog-description {
  color: gray;
  text-align: left;
  font-size: 1.1em;
  letter-spacing: 0.3px;
  padding: 10px 0px;
  color: gray;
  line-height: 1.5;
}
.blogsList-corosal-3cards-container {
  margin-top: 7%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.blogsList-corosal-container {
  min-height: 350px;
  width: 60%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.blogsList-3cards-container {
  width: 38%;
  display: flex;
}
.blogs-lists-and-blog-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2em 8em;
}
.blogs-lists {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 2.5em;
}
#blog-picture {
  height: 150px;
  width: 100%;
  border-radius: 0.5em;
}
.recent-blog-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  text-align: left;
  background: #f7f4f4;
  padding: 1em;
  border-radius: 1em;
  box-shadow: 3px 3px 8px #dad6d6;
}
.carousel-blogs {
  display: flex;
  flex-direction: row;
}
@media (max-width: 899px) {
  .carousel-blogs {
    display: flex;
    flex-direction: column;
  }
  .blogsList-body-content-container {
    width: 90%;
  }
  .blogs-lists-and-blog-container {
    padding: 1em;
  }
  .blogsList-corosal-3cards-container {
    width: 100%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
  }
  .blogsList-corosal-container {
    width: 100%;
    height: 280px;
  }
  .blogsList-3cards-container {
    width: 100%;
    margin-top: 10%;
    height: 400px;
  }
}
@media (max-width: 1400px) {
  .blogs-lists-and-blog-container {
    padding: 2em;
  }
}
@media (max-width: 1000px) {
  .blogs-lists-and-blog-container {
    padding: 2em;
    flex-direction: column;
  }
  .blogs-lists {
    width: 90%;
    margin: 1em;
    margin-right: 0;
  }
}

.blogsList-researchDesk-main-container {
  width: 100%;
  margin-top: 8%;
  margin-bottom: 5%;
}
.blogsList-researchDesk-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogsList-researchDesk-Heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 55px;
  align-items: center;
  margin: 0em 2em;
}
.researchDesk-Heading-bottom-line {
  height: 1px;
  background: #80808091;
}
.researchDesk-Research-desk-text {
  font-size: 20px;
  color: darkslategray;
}
.researchDesk-View-All-text {
  font-size: 18px;
  color: #ffd118;
}
.blogsList-researchDesk-body-container {
  width: 100%;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.research-desk-main-div {
  display: flex;
  flex-direction: column;
  width: 330px;
}
.research-desk-image-container {
  width: 100%;
  height: 170px;
  cursor: pointer;
}
.research-desk-text-container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid #eae6e6;
}
.research-desk-current-status-container {
  width: 100%;
  height: 40px;
  flex-direction: row;
  display: flex;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.research-desk-text-container-header {
  display: flex;
  color: #464646;
  font-weight: 500;
  justify-content: flex-start;
  margin: 15px 15px 0px 15px;
  align-items: baseline;
  font-size: 15px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
}
.research-desk-text-container-name-date {
  display: flex;
  margin: 9px 0 10px 14px;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  color: #959e9e;
  font-size: 12px;
}
.research-desk-text-container-Description {
  margin: 20px 0 20px 15px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 95%;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #5f5c5c;
  line-height: 1.7;
}
.research-desk-current-status-like-view-container {
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  padding: 10px;
  opacity: 0.9;
  margin-left: 10px;
}
.research-desk-current-status-Learn-more-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffd118;
  font-size: 12px;
  cursor: pointer;
  margin-right: 10px;
}

.research-desk-current-status-likes-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(110, 109, 109);
  padding: 8px;
}
.ReSearch-desk-Paper {
  margin-bottom: 50px;
  border-radius: 8px;
}
.research-desk-current-status-views-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(110, 109, 109);
  padding: 8px;
}
.blogListViews,
.blogListLikes {
  margin-left: 5px;
}
.ReSearch-desk-Paper:hover {
  box-shadow: 40px 40px 60px rgba(0, 0, 0, 0.2);
}
@media (max-width: 599px) {
  .research-desk-text-container-header {
    font-size: 17px;
    width: 100%;
  }
  .research-desk-text-container-name-date {
    font-size: 15px;
    margin: 15px;
  }
  .research-desk-text-container-Description {
    font-size: 15px;
  }
  .blogListViews,
  .blogListLikes {
    display: contents;
  }
  .ReSearch-desk-Paper {
    margin-bottom: 15px;
    border-radius: 8px;
  }
  .research-desk-current-status-Learn-more-container {
    color: #ffd118;
    font-size: 15px;
  }
  .research-desk-current-status-like-view-container {
    font-size: 12px;
  }
  .research-desk-main-div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .blogsList-researchDesk-container {
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .research-desk-main-div {
    display: flex;
    flex-direction: column;
    width: 320px;
    border-radius: 5px;
  }
  .research-desk-current-status-container {
    width: 100%;
    height: 100px;
    flex-direction: row;
    display: flex;
    background-color: white;
  }
  .research-desk-image-container {
    width: 100%;
    height: 200px;
  }
  .research-desk-text-container {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-bottom: 0.5px solid #eae6e6;
  }
  .research-desk-text-container-header {
    font-size: 16px;
  }
  .research-desk-text-container-name-date {
    font-size: 15px;
  }
  .research-desk-text-container-Description {
    font-size: 14px;
  }
  .research-desk-current-status-like-view-container {
    font-size: 14px;
  }
  .research-desk-current-status-Learn-more-container {
    font-size: 14px;
  }
  .research-desk-current-status-container {
    height: 45px;
  }
}

.HowMuchRiskCard-main-container {
  width: 100%;
}
.HowMuchRiskCard-body-container {
  width: 100%;
  height: 100%;
}
.HowMuchRiskCard-body-card-container {
  flex-direction: row;
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: white;
  text-align: left;
  border-radius: 4px;
}
.HowMuchRiskCard-body-card-image-container {
  width: 30%;
  margin: 1%;
  max-height: 90px;
  height: 85px;
}
.HowMuchRiskCard-body-card-text-container {
  width: 60%;
  display: flex;
  margin: 1%;
  flex-direction: column;
  justify-content: space-between;
}
.HowMuchRiskCard-text-Date-Read-more-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.HowMuchRiskCard-text-header-container {
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  color: hsla(0, 0%, 20%, 0.859);
  text-transform: capitalize;
}
.HowMuchRiskCard-text-Read-more-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #ffd118;
  white-space: nowrap;
}
.HowMuchRiskCard-text-Read-more-container:hover {
  cursor: pointer;
}
.HowMuchRiskCard-text-Date-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #bfbcbc;
}
.HowMuchRiskCard-text-Name-container {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #bfbcbc;
  width: 100%;
}

@media (min-width: 1400px) {
  .HowMuchRiskCard-body-card-container {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: white;
    border-radius: 26px;
    text-align: left;
  }
  .HowMuchRiskCard-text-Date-Read-more-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .HowMuchRiskCard-text-header-container {
    width: 100%;
    font-weight: normal;
    font-size: 17px;
    color: hsla(0, 0%, 20%, 0.859);
  }
  .HowMuchRiskCard-text-Name-container {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    color: #bfbcbc;
    width: 100%;
  }
  .HowMuchRiskCard-text-Read-more-container {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    color: #ffd118;
  }
  .HowMuchRiskCard-text-Date-container {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    color: #bfbcbc;
  }
}

.blog-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.slider-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.blog-slide {
  display: inline-block;
  height: 400px;
  width: 100%;
}

.circle1 {
  position: absolute;
  top: 85%;
  font-size: 50px;
  left: 45%;
  /*z-index: 999;*/
  background: transparent;
  color: #afa9a9a8;
}

.circle2 {
  position: absolute;
  top: 85%;
  font-size: 50px;
  left: 50%;
  /*z-index: 999;*/
  background: transparent;
  color: #afa9a9a8;
}
.circle3 {
  position: absolute;
  top: 85%;
  font-size: 50px;
  left: 55%;
  /* z-index: 999;*/
  background: transparent;
  color: #afa9a9a8;
}
.circle4 {
  position: absolute;
  top: 85%;
  font-size: 50px;
  left: 60%;
  /* z-index: 999;*/
  background: transparent;
  color: #afa9a9a8;
}
/* .circle1:hover,
.circle2:hover,
.circle3:hover,
.circle4:hover {
  color: #1715157a;
} */
.fa-arrow-right:before,
.fa-arrow-left:before {
  color: #222;
}
@media (max-width: 800px) {
  .circle1 {
    position: absolute;
    top: 85%;
    font-size: 50px;
    left: 40%;
    /*z-index: 999;*/
    background: transparent;
    color: #afa9a9a8;
  }

  .circle2 {
    position: absolute;
    top: 85%;
    font-size: 50px;
    left: 45%;
    /*z-index: 999;*/
    background: transparent;
    color: #afa9a9a8;
  }
  .circle3 {
    position: absolute;
    top: 85%;
    font-size: 50px;
    left: 50%;
    /* z-index: 999;*/
    background: transparent;
    color: #afa9a9a8;
  }
  .circle4 {
    position: absolute;
    top: 85%;
    font-size: 50px;
    left: 55%;
    /* z-index: 999;*/
    background: transparent;
    color: #afa9a9a8;
  }
}
/* @media (max-width: 800px) {
  .circle1 {
    position: absolute;
    top: 80%;
    left: 45%;
    z-index: 999;
    background: transparent;
    color: #fffafa;
  }

  .circle2 {
    position: absolute;
    top: 80%;
    left: 50%;
    z-index: 999;
    background: transparent;
    color: #fffafa;
  }
  .circle3 {
    position: absolute;
    top: 80%;
    left: 55%;
    z-index: 999;
    background: transparent;
    color: #fffafa;
  }
  .circle4 {
    position: absolute;
    top: 80%;
    left: 60%;
    z-index: 999;
    background: transparent;
    color: #fffafa;
  }
} */
.bottom-left {
  display: flex;
  color: white;
  height: inherit;
  align-items: flex-end;
  top: 0%;
  position: absolute;
  width: 100%;
}
.titleSlide {
  padding: 1em 2em;
  position: relative;
  bottom: 16%;
  color: black;
  background: #ffffffcf;
  min-width: 52%;
  box-shadow: 1px 2px 5px grey;
}

.TransparentBlackLayer {
  background-color: rgba(17, 17, 17, 0.839);
  height: 100%;
  width: 100%;
}
/*HOW IT WORKS MAIN COMPONENT SECTION STYLES */
.howItWorksMobileView {
  display: none;
}
.howItWorks-main-container {
  width: 100%;
}
.howItWorks-body-container {
  width: 100%;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.howItWorks-body-contents {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15%;
}
.howItWorks-dashed-line-div1 {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 0.5px solid black; */
}
.howItWorks-dashed-line1 {
  width: 3%;
  height: 100%;
  border-right-style: dashed;
  border-width: 2px;
  border-bottom-right-radius: 20px;
  border-color: #d5b441;
}
.howItWorks-dashed-line-div2 {
  width: 96%;
  height: 1px;
  display: flex;
  margin-left: 2%;
  /* border: 0.5px solid black; */
}
.howItWorks-dashed-line2 {
  width: 36%;
  height: 1%;
  margin-left: 14%;
  border-bottom-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
}
.howItWorks-dashed-line-div3 {
  width: 100%;
  height: 150px;
  display: flex;
  /* border: 0.5px solid black; */
}
.howItWorks-dashed-line3 {
  width: 1%;
  height: 100%;
  margin-left: 14%;
  border-left-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
  border-top-left-radius: 25px;
}
.howItWorks-card1-container {
  width: 100%;
  height: 245px;
  /* border: 0.5px solid black; */
  display: flex;
  flex-direction: row;
}
.howItWorks-card-no-1 {
  width: 35%;
  /* border: 1px solid blue; */
  display: flex;
}
.howItWorks-card-count-1 {
  width: 10%;
  /* border: 1px solid brown; */
  color: #fae89d;
  font-size: 65px;
  font-weight: 600;
  padding-top: 50px;
}
.howItWorks-card-no-1-text {
  width: 40%;
  height: 100%;
  /* border: 1px solid purple; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3%;
}
.howItWorks-card1-circle-section {
  height: 200px;
  width: 200px;
  background: #d4d4d4;
  border-radius: 150px;
  margin-left: 15%;
}
.howItWorks-card1-square-section {
  top: 20px;
  left: 20px;
  height: 230px;
  width: 230px;
  position: relative;
  background: white;
  box-shadow: 6px 0px 23px 1px rgba(0, 0, 0, 0.2),
    5px -1px 15px -7px rgba(0, 0, 0, 0.14),
    0px -1px 1px -2px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.howItWorks-card-text-heading {
  font-size: 21px;
  opacity: 0.8;
  font-weight: 500;
  margin-top: 15%;
  text-align: left;
}
.howItWorks-card-text-description {
  font-size: 17px;
  font-weight: 400;
  line-height: 2rem;
  margin-top: 5%;
  text-align: left;
  opacity: 0.8;
}
.howItWorks-dashed-line-div4 {
  width: 100%;
  height: 100px;
  display: flex;
}
.howItWorks-dashed-line4 {
  width: 1%;
  height: 100%;
  margin-left: 14%;
  border-left-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
  border-bottom-left-radius: 25px;
}
.howItWorks-dashed-line-div5 {
  width: 100%;
  height: 1px;
  display: flex;
}
.howItWorks-dashed-line5 {
  width: 68%;
  height: 1%;
  margin-left: 15%;
  border-bottom-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
}
.howItWorks-dashed-line-div6 {
  width: 100%;
  height: 100px;
  display: flex;
}
.howItWorks-dashed-line6 {
  width: 1%;
  height: 100%;
  margin-left: 83%;
  border-right-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
  border-top-right-radius: 25px;
}
.howItWorks-card2-container {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: row;
}
.howItWorks-card-count-2 {
  width: 10%;
  color: #fae89d;
  font-size: 65px;
  font-weight: 600;
  padding-top: 50px;
  margin-left: 10%;
}
.howItWorks-card-no-2-text {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3%;
}
.howItWorks-card-no-2-text-heading {
  font-size: 21px;
  opacity: 0.8;
  font-weight: 500;
  margin-top: 15%;
  text-align: left;
}
.howItWorks-card-no-2-text-description {
  font-size: 17px;
  font-weight: 400;
  line-height: 2rem;
  margin-top: 5%;
  text-align: left;
  opacity: 0.8;
}
.howItWorks-card-no-2-container {
  width: 35%;
  display: flex;
  justify-content: flex-end;
  margin-right: 7%;
}
.howItWorks-card-no-2-circle-container {
  height: 200px;
  width: 200px;
  background: #d4d4d4;
  border-radius: 150px;
}
.howItWorks-card-no-2-square-coantainer {
  top: 20px;
  right: 45px;
  height: 230px;
  width: 230px;
  position: relative;
  background: white;
  box-shadow: 4px 0px 23px 1px rgba(0, 0, 0, 0.2),
    -2px -1px 15px -7px rgba(0, 0, 0, 0.14),
    0px -1px 1px -2px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.howItWorks-card-no-3 {
  width: 35%;
  display: flex;
  align-items: flex-end;
}
.howItWorks-card3-circle3-section {
  height: 200px;
  width: 200px;
  background: #d4d4d4;
  border-radius: 150px;
  margin-left: 20%;
}
.howItWorks-card3-square3-section {
  bottom: 50px;
  right: 50px;
  height: 230px;
  width: 230px;
  position: relative;
  background: white;
  box-shadow: 0px 2px 23px 0px rgba(0, -0, 0, 0.2),
    5px -5px 0px -7px rgba(0, 0, 0, 0.14), 0px -1px 0px -1px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.howItWorks-card-no-4 {
  width: 35%;
  display: flex;
  align-items: flex-end;
}
.howItWorks-card4-circle4-section {
  height: 200px;
  width: 200px;
  background: #d4d4d4;
  border-radius: 150px;
  margin-left: 35%;
}
.howItWorks-card4-square4-section {
  bottom: 55px;
  left: 20px;
  height: 230px;
  width: 230px;
  position: relative;
  background: white;
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2),
    5px -1px 15px -7px rgba(0, 0, 0, 0.14),
    0px -1px 1px -2px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.howItWorks-card-no-6-square-coantainer {
  top: 25px;
  right: 45px;
  height: 230px;
  width: 230px;
  position: relative;
  background: white;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2),
    -2px -1px 1px -7px rgba(0, 0, 0, 0.14),
    0px -1px 1px -2px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.howItWorks-dashed-line-div7 {
  width: 100%;
  height: 100px;
  display: flex;
}
.howItWorks-dashed-line7 {
  width: 1%;
  height: 100%;
  margin-left: 83%;
  border-right-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
  border-bottom-right-radius: 25px;
}
.howItWorks-dashed-line-div8 {
  width: 100%;
  height: 150px;
  display: flex;
}
.howItWorks-dashed-line8 {
  width: 1%;
  height: 100%;
  margin-left: 14%;
  border-left-style: dashed;
  border-width: 2px;
  border-color: #d5b441;
  border-top-left-radius: 25px;
}
.howItWorks-card1-square-section:hover,
.howItWorks-card4-square4-section:hover,
.howItWorks-card3-square3-section:hover,
.howItWorks-card-no-2-square-coantainer:hover,
.howItWorks-card-no-6-square-coantainer:hover {
  transform: scale(1.2);
  z-index: 1;
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
}
.howItWorks-card-text-container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.howItWorks-card-number-text-container {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: left;
  color: #fae89d;
  font-size: 40px;
  font-weight: 700;
}
.howItWorks-card-description-text-container {
  flex: 2 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: left;
  font-size: 19px;
  font-weight: 500;
  opacity: 0.7;
  /* letter-spacing: 0.3px; */
}
@media (max-width: 400px) {
  .howItWorks-body-contents {
    display: none;
  }
  .howItWorksMobileView {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
  }
  .howItWorks-card-main-container {
    width: 300px;
    height: 450px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .howItWorks-card-image-container {
    flex: 2 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: row;
  }
  .howItWorks-card1-circle-section {
    height: 200px;
    width: 200px;
    background: #d4d4d4;
    border-radius: 150px;
    margin-left: 10%;
  }
}
@media (max-width: 600px) {
  .howItWorks-body-contents {
    display: none;
  }
  .howItWorksMobileView {
    display: flex;
    width: 100%;

    justify-content: center;
    align-items: flex-start;
    padding-bottom: 10%;
  }
  .howItWorks-card-main-container {
    width: 300px;
    height: 450px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .howItWorks-card-image-container {
    flex: 2 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .howItWorks-card1-circle-section {
    height: 200px;
    width: 200px;
    background: #d4d4d4;
    border-radius: 150px;
    margin-left: 10%;
  }
}
@media (max-width: 1000px) and (min-width: 601px) {
  .howItWorks-body-contents {
    display: none;
  }
  .howItWorksMobileView {
    display: flex;
    width: 100%;

    justify-content: center;
    align-items: flex-start;
    padding-bottom: 10%;
  }
  .howItWorks-card-main-container {
    width: 300px;
    height: 450px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .howItWorks-card-image-container {
    flex: 2 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .howItWorks-card1-circle-section {
    height: 200px;
    width: 200px;
    background: #d4d4d4;
    border-radius: 150px;
    margin-left: 10%;
  }
}
@media (min-width: 1800px) {
  .howItWorks-card1-circle-section {
    height: 250px;
    width: 250px;
    background: #d4d4d4;
    border-radius: 150px;
    margin-left: 15%;
  }
  .howItWorksMobileView {
    display: none;
  }
  .howItWorks-card1-square-section {
    height: 270px;
    width: 270px;
  }
  .howItWorks-card-text-heading {
    font-size: 29px;
    opacity: 0.8;
    font-weight: 500;
    margin-top: 10%;
  }
  .howItWorks-card4-circle4-section,
  .howItWorks-card3-circle3-section,
  .howItWorks-card-no-2-circle-container {
    height: 250px;
    width: 250px;
  }
  .howItWorks-card4-square4-section,
  .howItWorks-card3-square3-section,
  .howItWorks-card-no-2-square-coantainer,
  .howItWorks-card-no-6-square-coantainer {
    height: 270px;
    width: 270px;
  }
  .howItWorks-card-no-2-text-heading,
  .howItWorks-card-text-heading {
    font-size: 27px;
  }
  .howItWorks-card-no-2-text-description,
  .howItWorks-card-text-description {
    font-size: 20px;
  }
  .howItWorks-card-count-1,
  .howItWorks-card-count-2 {
    font-size: 80px;
  }
}
/* HOW IT WORKS HEADER SECTION STYLES */

.howItWorks-heading-container {
  padding-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.howItWorks-heading-text-image-container {
  width: 90%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  background: transparent;
  color: white;
  margin-bottom: 7%;
  padding-top: 6%;
}

.howItWorks-header-heading-container {
  font-size: 45px;
  font-weight: 300;
  margin-bottom: 10%;
}

.howItWorks-heading-text-container {
  width: 45%;
  text-align: left;
  text-align: initial;
  justify-content: center;
  letter-spacing: 0.5px;
  padding-top: 0;
  color: white;
}

.howItWorks-header-description-container {
  line-height: 2em;
  font-size: 15px;
  color: #d7dce2d9;
  text-align: justify;
  letter-spacing: 0.4px;
}

.howItWorksVideo {
  height: 30%;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.howItWorksVideoIframe {
  height: 100%;
  width: 100%;
}

@media (max-width: 599px) {
  .howItWorks-heading-text-image-container {
    flex-direction: column;
  }
  .howItWorks-header-heading-container {
    font-size: 35px;
    font-weight: 300;
    margin-top: 10%;
    margin-bottom: 10%;
    text-align: left;
  }
  .howItWorks-header-description-container {
    text-align: left;
  }
  .howItWorks-heading-text-container {
    width: 95%;
    text-align: center;
  }
  .howItWorks-header-description-container {
    line-height: 2em;
    font-size: 14px;
  }
  .howItWorksVideo {
    width: 100%;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .howItWorksVideoIframe {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 850px) and (min-width: 600px) {
  .howItWorks-heading-text-image-container {
    flex-direction: column;
  }
  .howItWorks-heading-text-container {
    width: 95%;
    font-size: 25px;
  }
  .howItWorksVideo {
    margin-top: 10%;
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .howItWorksVideoIframe {
    height: 520px;
    width: 520px;
  }
}

@media (min-width: 1800px) {
  .howItWorks-heading-text-image-container {
    flex-direction: row;
  }
  .howItWorks-heading-text-container {
    width: 45%;
  }
  .howItWorks-header-description-container {
    line-height: 2em;
    font-size: 18px;
  }
  .howItWorksVideo {
    height: 20%;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .howItWorks-header-heading-container {
    font-size: 45px;
    font-weight: lighter;
  }
  .howItWorksVideoIframe {
    height: 500px;
  }
}

.TransparentBlackLayer dialog {
  max-width: 800px;
}

.Partners-main-container {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Partners-heading-text-container {
  height: 100px;
  width: 100%;
  font-size: 36px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  opacity: 0.7;
}
.Partners-body-container {
  flex: 1 1;
  width: 100%;
  display: flex;
  margin-bottom: 5%;
}
.Partners-zerogha-image,
.Partners-angelbroking-image,
.Partners-upstoxs-image,
.Partners-mymoneysage-image {
  display: none;
}

.partners-logo-list {
  display: grid;
  justify-content: center;
  grid-template-columns: 20em 20em 20em 20em;
  grid-gap: 1em 1em;
  height: 100%;
  padding: 2em;
}

@media (max-width: 81.125em) {
  .partners-logo-list {
    display: grid;
    justify-content: center;
    grid-template-columns: 20em 20em 20em;
    grid-gap: 1em 1em;
    height: 100%;
    padding: 2em;
  }
}

@media (max-width: 59.3125em) {
  .partners-logo-list {
    display: grid;
    justify-content: center;
    grid-template-columns: 20em 20em;
    grid-gap: 1em 1em;
    height: 100%;
    padding: 2em;
  }
}

@media (max-width: 39.3125em) {
  .partners-logo-list {
    display: grid;
    justify-content: center;
    grid-template-columns: 20em;
    grid-gap: 2em;
    height: 100%;
    padding: 2em;
  }
}

@media (max-width: 699px) {
  .Partners-heading-text-container {
    font-size: 28px;
  }
  .Partners-main-container {
    height: 100%;
  }
  .Partners-body-container {
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
  }
  .Partners-zerogha-image,
  .Partners-angelbroking-image,
  .Partners-upstoxs-image,
  .Partners-mymoneysage-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .slider {
    display: none;
  }
}
/* @keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-150px * 5));
  }
} */
.slider {
  background: white;
  height: 200px;
  margin: auto;
  overflow: hidden;
  /* width: 1100px; */
  justify-content: center;
  align-items: center;
}
.slider::before,
.slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 30s linear infinite;
          animation: scroll 30s linear infinite;
  display: flex;
  width: calc(250px * 14);
  align-items: center;
}
.slider .slide {
  height: 100px;
  width: 350px;
}

@media (min-width: 700px) {
  .slider {
    display: flex;
  }
}

.gallery-Images-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.gallery-images-header-contaier {
  height: 90px;
  background-color: black;
  box-shadow: 0 8px 6px -6px rgb(44, 38, 38);
}
.gallery-images-heading,
.gallery-Videos-heading {
  color: black;
  opacity: 0.7;
  margin-top: 5%;
  font-size: 36px;
}
.gallery-images-body-container,
.gallery-videos-body-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.gallery-images-container,
.gallery-videos-container {
  width: 80%;
  margin-top: 8%;
}
.gallery-images,
.gallery-videos {
  height: 250px;
  width: 350px;
  border: 2px solid #fbb938;
}

.planing-component-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.planing-component-heading-container {
  display: flex;
  flex: 1 1;
  margin: 8%;
  margin-top: 20%;
  margin-bottom: 20%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.planing-component-header-text-container {
  font-size: 45px;
  font-weight: 300;
  color: white;
}

.planing-component-inputYear {
  text-align: right;
  width: 30px;
  border: none;
  padding: 4px 0px;
  margin: 0px 4px;
  font-size: 14px;
  color: rgb(102, 102, 102);
}
.planing-component-description-text-container {
  font-weight: normal;
  margin-top: 2%;
  line-height: 1.7em;
  letter-spacing: 0.3px;
  font-size: 15px;
  color: #d7dce2d9;
}
.planing-component-plan-description-section {
  width: 65%;
  height: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: white;
  text-align: justify;
  position: relative;
  top: 100px;
  border-radius: 3px;
  flex-direction: row;
  display: flex;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.11),
    -2px -1px 1px -7px rgba(0, 0, 0, 0.11), 0px -1px 1px -2px rgba(0, 0, 0, 0);
}
.planing-component-plan-image-section {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.planing-component-plan-text-section {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.planing-component-plan-text-heading-section {
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.planing-component-plan-text-description-section {
  font-size: 14px;
  font-weight: 400;
  margin-top: 2%;
  color: rgb(110, 109, 109);
  line-height: 2em;
  text-align: left;
  text-align: left;
}
.planing-components-body-container {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.planning-components-body-content-containers {
  width: 90%;
  height: 100%;
  /* border: 0.5px solid blue; */
  margin-top: 15%;
  margin-bottom: 20%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.planning-components-body-period-types-containers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* border: 0.5px solid yellow; */
}
.planning-components-body-period-name-containers {
  width: 100%;
  font-weight: 300;
  font-size: 35px;
}
.planning-components-body-period-selectors-containers {
  width: 60%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 5%;
  /* border: 0.5px solid blue; */
}
.period-selectors-onetime,
.period-selectors-monthly {
  display: flex;
  flex-direction: row;
  width: 40%;
  /* border: 0.5px solid palevioletred; */
  height: 100%;
  justify-content: center;
}
.period-selectors-onetime-radiobutton {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 0.5px solid peru; */
}
.period-selectors-onetime-text {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: 400;
  cursor: pointer;
  /* border: 0.5px solid peru; */
}
.planning-components-body-invested-excepted-containers {
  width: 70%;
  margin-top: 10%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.11),
    -2px -1px 1px -7px rgba(0, 0, 0, 0.11), 0px -1px 1px -2px rgba(0, 0, 0, 0);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  padding: 3%;
}
.planning-components-investment-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  /* border: 0.5px solid blueviolet; */
  align-items: center;
}
.planning-components-Excepted-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  /* border: 0.5px solid rgb(23, 4, 41); */
  align-items: flex-start;
}
.excepted-returns-contents-container {
  width: 90%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 0.5px solid #ffd118;
}
.expected-yearscontainers {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2%;
}
.expected-noof-year-containers {
  color: rgb(110, 109, 109);
  font-size: 14px;
  background-color: black;
  width: 30px;
  border-radius: 10px;
  cursor: pointer;
}
.planning-components-body-invested-projectd-container {
  margin-top: 6%;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.invested-value-container,
.expected-value-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3%;
  padding-top: 3%;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.11),
    2px 2px 7px 0px rgba(0, 0, 0, 0.11), 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.expected-value-container {
  outline: 1px solid black;
}

.invested-value-header {
  font-size: 16px;
  font-weight: 500;
  color: rgb(110, 109, 109);
}
.invested-value-text {
  margin-top: 5%;
  font-size: 20px;
}
.section-devider {
  height: 150px;
  border-left: 1px solid rgba(110, 109, 109, 0.4);
  width: 10px;
  margin-left: 2%;
  margin-top: 4%;
  margin-right: 2%;
}
.planning-components-investment-amount-container {
  width: 100%;
  margin-top: 15%;
  font-size: 45px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* border: 0.5px solid red; */
}
.planning-amount {
  opacity: 0.87;
}
.planning-components-range-slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}
.amount-slider {
  -webkit-appearance: none;
  width: 80%;
  height: 5px;
  border-radius: 5px;
  background: #ffd118;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.amount-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.87)), to(rgba(0, 0, 0, 0.986)));
  background-image: linear-gradient(rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.986));
  cursor: pointer;
}

.amount-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  cursor: pointer;
}
.slider-increase-decrase-buttons {
  width: 80%;
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.planning-components-body-plan-info-containers {
  width: 80%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  /* border: 0.5px solid gold; */
}
.plan-info-heading-text-container {
  width: 100%;
  text-align: left;
  font-size: 21px;
  font-weight: 400;
}
.plan-info-description-text-container {
  font-size: 14px;
  font-weight: 400;
  margin-top: 2%;
  color: rgb(110, 109, 109);
  line-height: 1.7rem;
  text-align: left;
}
.planning-components-body-returns-comparision-containers {
  width: 80%;
  margin-top: 10%;
  display: flex;
  /* border: 0.5px solid rgb(0, 255, 76); */
  flex-direction: column;
}
.returns-comparing-charts-heading {
  width: 100%;
  text-align: left;
  font-size: 21px;
  font-weight: 400;
}
.returns-comparing-charts {
  margin-top: 3%;
  height: 500px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.11),
    -2px -1px 1px -7px rgba(0, 0, 0, 0.11), 0px -1px 1px -2px rgba(0, 0, 0, 0);
  border-radius: 3px;
  background-color: white;
}
.radiocontainer {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.radiocontainer input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: #5a5f73;
  border-radius: 50%;
}
.radiocontainer input:checked ~ .checkmark {
  background-color: #5a5f73;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radiocontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radiocontainer .checkmark:after {
  top: 7px;
  left: 7px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ffd118;
}
.radiocontainer input:checked ~ .checkmark:after {
  display: block;
}

#slider1 {
  position: relative;
  right: 8%;
}
#slider2 {
  padding-left: 10%;
}

#slider3 {
  padding-left: 28%;
}

#slider4 {
  padding-left: 42%;
}

#sliderContainer5 {
  justify-content: flex-end;
}

@media (max-width: 700px) {
  #slider1 {
    left: -10%;
  }
  #slider2 {
    padding-left: 5%;
  }

  #slider3 {
    padding-left: 20%;
  }

  #slider4 {
    padding-left: 32%;
  }
}

@media (max-width: 474px) {
  #slider3 {
    padding-left: 10%;
  }

  #slider4 {
    padding-left: 21%;
  }
}

@media (max-width: 320px) {
  #slider2,
  #slider3,
  #slider4,
  #slider5 {
    padding-left: 0;
    position: none;
    left: 0;
  }
}

@media (max-width: 899px) {
  .planing-component-plan-description-section {
    width: 90%;
    height: 100%;
    flex-direction: column;
    justify-content: justify;
    align-items: center;
    padding: 1%;
    top: 150px;
  }
  .planing-component-plan-text-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .planing-component-plan-text-heading-section,
  .planing-component-plan-text-description-section {
    text-align: left;
    padding-top: 3%;
  }
  .planning-report-image {
    width: 100%;
  }
  .planning-components-body-content-containers {
    margin-top: 200px;
    margin-bottom: 55%;
  }
  .planning-components-body-period-selectors-containers {
    width: 100%;
  }
  .period-selectors-onetime,
  .period-selectors-monthly {
    width: 60%;
  }
  .planning-components-body-invested-excepted-containers {
    flex-direction: column;
    justify-content: space-between;
    width: 85%;
    padding: 4%;
  }
  .planning-components-investment-container {
    width: 100%;
  }
  .planning-components-Excepted-container {
    width: 100%;
    align-items: center;
  }
  .planning-components-investment-amount-container {
    width: 100%;
    margin-top: 8%;
  }
  .section-devider {
    height: 1px;
    border-top: 1px solid rgba(110, 109, 109, 0.4);
    width: 100%;
    margin-bottom: 10px;
  }
  .planning-components-body-plan-info-containers {
    width: 90%;
    /* border: 0.5px solid gold; */
  }
  .planning-components-body-returns-comparision-containers {
    width: 90%;
  }
  .excepted-returns-contents-container {
    margin-top: 6%;
  }
}
@media (max-width: 599px) {
  .planning-components-body-invested-projectd-container {
    flex-direction: column;
  }
  .invested-value-container,
  .expected-value-container {
    width: 85%;
  }
  .expected-value-container {
    outline: 1px solid black;
  }
}

.Products-main-container {
  width: 100%;
  height: 300px;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Products-body-container {
  display: flex;
  flex: 0.8 1;
  justify-content: center;
  align-items: center;
  width: 85%;
}
.product-logo-container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.product-logo-container > img {
  width: 250px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
}
.product-logo-container > img:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
@media (min-width: 769px) {
  .Products-body-container {
    flex-direction: row;
  }
}
@media (max-width: 768px) {
  .Products-body-container {
    flex-direction: column;
  }
}

.small-case-main {
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  padding-bottom: 30px;
}
.background-text {
  font-size: 7rem;
  font-weight: 700;
  letter-spacing: 9px;
  color: #fbd2498a;
  z-index: 0;
  overflow: hidden;
}
.smallcase-heading-text-container {
  height: 80px;
  width: 100%;
  font-size: 36px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  opacity: 0.7;
  margin-top: 30px;
}
.smallcase-text-image-container {
  height: 100%;
  width: 80%;
  font-size: 18px;
  color: black;
  opacity: 0.7;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 0;
}
.smallcase-text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: column;
  text-align: left;
}
.text-container1 {
  font-size: 32px;
  color: black;
  font-weight: 300;
  opacity: 0.7;
  line-height: 1.4;
}
.text-container2 {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: -0.1px;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 20px;
}
.smallcase-image-container {
  width: 30%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.brokers-icons-container {
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
}
.broker-icon {
  max-width: 40px;
  height: 40px;
  background: white;
  border-radius: 26px;
  margin-right: 20px;
  padding: 10px;
  -webkit-transition-property: width; /* Safari */
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-property: width;
  transition-duration: 0.5s;
  box-shadow: 1px 1px 5px #040404a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.broker-icon:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: pointer;
}
.smallcase-cards {
  min-height: 350px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.smallcase-card-1 {
  max-width: 480px;
  min-height: 300px;
  background-image: url(/static/media/GTurns_Momentum.27936430.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  background-color: white;
  box-shadow: 0 10px 24px 0 rgba(250, 250, 250, 0.2),
    0 8px 16px 0 rgba(33, 33, 33, 0.3);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: black;
}
.smallcase-card-1:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: pointer;
}
.smallcase-card-header {
  padding: 10px 0;
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  opacity: 0.7;
}
.smallcase-card-description {
  padding: 20px 0px;
  text-align: left;
  width: 70%;
  font-weight: 400;
  opacity: 0.7;
  line-height: 1.4;
}
.smallcase-card-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.smallcase-bottom-link {
  padding: 30px 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  color: black;
  opacity: 0.6;
}
.arrow-icon-bottom-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b18a14;
  width: 20px;
  -webkit-transition-property: width; /* Safari */
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-property: width;
  transition-duration: 0.5s;
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
          transform: matrix(1, 0, 0, 1, 0, 0);
}
.arrow-text-bottom-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b18a14;
  -webkit-transition-property: width; /* Safari */
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-property: width;
  transition-duration: 0.5s;
  width: 320px;
}
.arrow-text-bottom-link:hover {
  width: 340px;
  cursor: pointer;
  font-weight: 700;
  -webkit-transform: translate(2%, 0%) matrix(1, 0, 0, 1, 0, 0);
          transform: translate(2%, 0%) matrix(1, 0, 0, 1, 0, 0);
}
.gcore-product-Info {
  width: 90%;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-top: 0.1px solid #eae8e8;
}
.gcore-description {
  letter-spacing: 0.3px;
  line-height: 1.8;
  width: 60%;
  text-align: left;
  color: black;
  opacity: 0.7;
}
@media (max-width: 800px) {
  .smallcase-cards {
    padding: 0px;
    width: 100%;
  }
  .smallcase-heading-text-container {
    font-size: 28px;
  }
  .smallcase-card-1 {
    min-height: 230px;
    width: 90%;
    padding: 20px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .smallcase-text-image-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 0;
    flex-direction: column;
    width: 90%;
  }
  .smallcase-text-container {
    width: 100%;
  }
  .brokers-icons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .smallcase-image-container {
    width: 100%;
    align-items: center;
    padding-top: 80px;
  }
  .background-text {
    font-size: 3.5rem;
  }
  .text-container1 {
    font-size: 24px;
  }
  .gcore-product-Info {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }
  .gcore-description {
    letter-spacing: 0.3px;
    line-height: 1.8;
    width: 100%;
    text-align: justify;
    color: black;
    opacity: 0.7;
    padding: 10px;
  }
  .broker-icon {
    margin-right: 15px;
  }
}

