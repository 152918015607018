.blog-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #f7f7f7;
}
.blog-main-header-container {
  height: 90px;
  width: 100%;
  background-color: black;
  box-shadow: 0 8px 6px -6px rgb(44, 38, 38);
}
.blog-body-container {
  margin-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* border: 1px solid blue; */
}
.blog-body-contents-container {
  /* margin-top: 5%; */
  width: 80%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid blue; */
}
.blog-header-container {
  width: 100%;
  /* border: 1px solid black; */
}
.blog-header-text-container {
  font-size: 36px;
  font-weight: 300;
  display: flex;
  text-align: left;
  color: black;
  opacity: 0.7;
}
.blog-name-date-like-view-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* margin-top: 2%; */
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.8;
  text-align: left;
}
.blog-body-text-1-container {
  margin-top: 5%;
  color: #464141;
  font-size: 18px;
  text-align: justify;
  letter-spacing: 0.2px;
  line-height: 3em;
  line-height: 1.8;
  text-align: left;
}
.blog-body-text-2-container,
.blog-body-text-3-container {
  margin: 2% 0;
  color: #464141;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 2em;
  text-align: left;
  white-space: wrap;
}
.blog-body-text-4-container {
  margin-top: 10%;
  color: #464141;
  font-size: 15px;
  text-align: justify;
  letter-spacing: 0.2px;
  line-height: 1.5em;
}
.blog-name-date-container {
  display: flex;
  flex-direction: row;
}
.blog-likes-view-container {
  display: flex;
  flex-direction: row;
}
.blog-name-container {
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.blog-date-container {
  color: gray;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.blog-likes-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray;
  padding: 15px;
}
.blog-views-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray;
  padding: 15px;
}

@media (min-width: 600px) {
  .blog-body-text-1-container,
  .blog-body-text-2-container > p {
    margin-top: 1em;
    margin-bottom: 0.8em;
    text-align: justify;
    font-size: 16px;
    line-height: 1.8;
  }
  .blog-body-text-1-container,
  .blog-body-text-2-container > ul {
    margin: 1em 3em;
    font-size: 16px;
    text-align: justify;
    line-height: 1.8;
  }
  .divider-next-pervious {
    display: flex;
    justify-content: space-between;
    width: 85%;
  }
}
@media (max-width: 599px) {
  .divider-next-pervious {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .blog-body-text-1-container,
  .blog-body-text-2-container > p {
    margin-top: 1em;
    margin-bottom: 0.8em;
    text-align: left;
    font-size: 16px;
    line-height: 1.8;
  }
  .blog-body-text-1-container,
  .blog-body-text-2-container > ul {
    /* margin: 0; */
    margin: 1em 2em;
    text-align: left;
    font-size: 16px;
    line-height: 1.8;
  }

  .blog-header-text-container {
    font-size: 28px;
    letter-spacing: 0.2px;
    opacity: 0.7;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: left;
    color: black;
  }
  .blog-name-date-like-view-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* margin-top: 2%; */
    text-align: justify;
    justify-content: space-between;
    align-items: center;
    font-size: calc(8px + 1vmin);
  }
  .likes,
  .views {
    display: none;
  }
}
@media (min-width: 1450px) {
  .blog-main-header-container {
    /* height: 80px; */
    width: 100%;
    background-color: black;
  }
}
/* 
.blog-body-text-1-container,
.blog-body-text-2-container > p {
  margin-top: 1em;
  margin-bottom: 0.8em;
  text-align: justify;
  font-size: 16px;
} */
/* .blog-body-text-1-container,
.blog-body-text-2-container > br {
  visibility: hidden;
} */

strong {
  text-transform: capitalize;
}
.blog-body-text-1-container,
.blog-body-text-2-container > a {
  white-space: pre-wrap;
  font-size: 14px;
  opacity: 0.7;
  line-height: 1.5;
  text-align: center;
}
/* blockquote {
  margin: 3em 0;
} */
/* li {
  margin: 3em 0;
} */

#previous-container-active {
  margin-right: 30px;
  display: flex;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: gray;
  outline: none;
}
#next-container-active {
  display: flex;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: gray;
  outline: none;
}
#next-container-active:hover,
#previous-container-active:hover {
  color: rgb(255, 209, 24);
  cursor: pointer;
}
#previous-container-inActive {
  margin-right: 30px;
  display: flex;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #808080ad;
  outline: none;
  cursor: "auto";
}
#next-container-inActive {
  display: flex;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #808080ad;
  outline: none;
  cursor: auto;
}
