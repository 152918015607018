.faq-main-container {
  width: 100%;
  /* height: px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.faq-header-container {
  width: 100%;
  /* height: 200px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fafafa;
  font-size: 45px;
  font-weight: 300;
}
.faq-header-description-container {
  font-size: 15px;
  color: #d7dce2d9;
  line-height: 2em;
  text-align: justify;
}
.faq-body-container {
  width: 85%;
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: initial;
  padding-top: 5%;
}
.faq-expansion-panel {
  width: 100%;
  border-radius: 0px;
}
.faq-typography-style {
  color: white;
}
.faq-panel-lower-border {
  height: 1px;
  background: #a090908c;
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.faq-heading-text-image-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: transparent;
  margin-top: 6%;
  margin-bottom: 4%;
}
.faq-heading-text-container {
  width: 45%;
  text-align: initial;
  justify-content: space-around;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  padding: 15px;
  margin-left: 4%;
  font-size: 45px;
  font-weight: 300;
  /* color: #f1f1f1; */
  color: white;
}
.faq-heading-image-container {
  width: 55%;
  /* height: 100%; */
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid red;
}
.faq-questions-container {
  background-color: white;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.faq-heading-text-image-container > img {
  height: 50%;
}
.faq-answer-image > img {
  width: "90%";
}
.faq-search-box-container{
width:60%;
margin-top: 40px;  
margin-bottom:40px;
display: flex;
justify-content: center;
align-items: center;
box-shadow: 0px 0px 9px #00000040;
border-radius: 40px;
background: white;
}
.faq-search-box-container:hover{
  box-shadow: 0px 0px 9px #0000006a;
  /* border: 3px solid rgb(255, 209, 24); */
  }
@media (max-width: 599px) {
  .faq-heading-text-image-container {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    background: transparent;
    padding-top: 10%;
  }
  .faq-header-container {
    font-size: 28px;
    font-weight: 300;
  }
  .faq-heading-text-container {
    font-weight: normal;
    width: 85%;
    text-align: justify;
    justify-content: space-around;
    padding: 15px;
    font-size: 28px;
    font-weight: 500;
    color: white;
    margin-left: 0%;
  }
  .faq-heading-image-container {
    width: 80%;
    /* height: 100%; */
    justify-content: center;
    display: flex;
    align-items: center;
    padding-bottom: 6%;
  }
  .faq-questions-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 7%;
  }
  .faq-heading-image-container {
    width: 100%;
  }
  .faq-heading-text-image-container > img {
    height: 250px;
  }
  .faq-answer-image > img {
    width: 250;
  }
  .faq-search-box-container{
    width:80%;  
    margin-bottom: 40px;
    }
}
@media (min-width: 1800px) {
  .faq-header-description-container {
    font-size: 25px;
  }
  .faq-heading-text-container {
    font-size: 60px;
  }
  .faq-header-container {
    font-size: 45px;
  }
}
