.Homepage-container {
  background-color: rgba(14, 14, 14, 0.88);
  padding-top: 3%;
  flex: 1;
  color: rgb(250, 250, 250);
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;
  flex: 1;
  flex-direction: column;
}

@media (min-width: 600px) {
  .Homepage-banner1 {
    height: 650px;
    width: 100%;
  }
  .background-image-contain {
    display: flex;
    width: 100%;
    margin-top: 10%;
    flex-direction: column;
  }
}
@media (max-width: 599px) {
  .Homepage-banner1 {
    height: 398px;
    width: 100%;
  }
  .background-image-contain {
    display: flex;
    width: 100%;
    margin-top: 65px;
    flex-direction: column;
  }
}
.Homepage-heading {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 80%;
  border-radius: 5px;
  background-color: #6563631f;
  box-shadow: 0px 0px 30px #000;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Homepage-heading-text-title {
  margin-top: 8%;
  font-size: 45px;
  font-weight: 300;
  letter-spacing: 0.8px;
}
.Homepage-heading-text-description {
  margin-top: 6%;
  font-size: 15px;
  width: 100%;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  text-align: center;
  color: #d7dce2d9;
}
.Homepage-heading-icons {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #e8e8e863;
}
.dot-icon:hover {
  color: white;
}
.bottom-tab-container {
  height: 100px;
  display: flex;
  background: rgba(0, 0, 0, 0.41);
  width: 100%;
  justify-content: center;
  align-items: center;
}
.svg-text-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.rupee-icon-text {
  border: 0px solid red;
  text-align: left;
  padding: 20;
  padding-top: 10;
  letter-spacing: 0.3px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7;
}
@media (max-width: 599px) {
  .Homepage-container {
    /* background-color: rgba(14, 14, 14, 0.95); */
    padding-top: 15%;
    color: rgb(250, 250, 250);
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .Homepage-heading {
    width: 90%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .Homepage-heading-text-title {
    font-size: 28px;
    font-weight: 500;
  }
  .Homepage-heading-text-description {
    margin-top: 7%;
    font-size: 13px;
  }
  .Homepage-heading-icons {
    margin-top: 15%;
    margin-bottom: 10%;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .bottom-tab-container {
    height: 200px;
  }
  .svg-text-container {
    flex-direction: column;
  }
  .rupee-icon-text {
    border: 0px solid red;
    text-align: center;
    padding: 20;
    padding-top: 10;
    font-size: 12px;
    letter-spacing: 0.3px;
    font-weight: 300;
    line-height: 1.7;
  }
}
@media (min-width: 1880px) {
  .Homepage-heading {
    width: 72%;
  }
  .Homepage-heading-text-title {
    font-size: 60px;
  }
  .Homepage-heading-text-description {
    font-size: 30px;
  }
}

/*********************** Slide show Styles ***************/
* {
  box-sizing: border-box;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
.mySlides {
  display: none;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1500px;
  position: relative;
  margin: auto;
  min-width: 1080px;
}

/* Next & previous buttons */
.prev {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  left: 0;
}
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  right: 0;
}
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}
