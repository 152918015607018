.journey-Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.journey-heading-container {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  font-size: 36px;
  font-weight: 300;
  padding-top: 8%;
  padding-bottom: 5%;
}

.image-container > img {
  /*width: 80%;
  height: 80%;*/
  margin-bottom: 4%;
}

@media (max-width: 599px) {
  .journey-Container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .journey-heading-container {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    font-size: 28px;
    padding-top: 8%;
    padding-bottom: 5%;
  }

  .image-container > img {
    width: 90%;
    height: 100%;
    margin-bottom: 10%;
  }
}
