.testimonials-container {
  width: 100%;
  background-color: rgba(26, 26, 26, 0.836);
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
}
.testimonial-card-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
}
.card-container {
  height: 100%;
  width: 37%;
  background-color: transparent;
}
.card {
  height: 100%;
  width: 100%;
  color: rgb(241, 241, 241);
}
.testimonials-heading {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  letter-spacing: 1px;
  font-size: 36px;
  font-weight: 300;
  padding-top: 8%;
}
@media (min-width: 600px) {
  .testimonial-cardContainer {
    display: flex;
    flex-direction: column;
  }
  .testimonial-message-and-customer-name-container {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 860px) {
  .testimonial-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .testimonials-heading {
    font-size: 28px;
  }
}
@media (max-width: 599px) {
  .testimonial-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .testimonials-heading {
    font-size: 28px;
  }

  .card-container {
    height: 100%;
    width: 90%;
  }
  .testimonial-cardContainer {
    display: flex;
    flex-direction: column;
    /* height: 300px; */
  }
}
