.gallery-Images-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.gallery-images-header-contaier {
  height: 90px;
  background-color: black;
  box-shadow: 0 8px 6px -6px rgb(44, 38, 38);
}
.gallery-images-heading,
.gallery-Videos-heading {
  color: black;
  opacity: 0.7;
  margin-top: 5%;
  font-size: 36px;
}
.gallery-images-body-container,
.gallery-videos-body-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.gallery-images-container,
.gallery-videos-container {
  width: 80%;
  margin-top: 8%;
}
.gallery-images,
.gallery-videos {
  height: 250px;
  width: 350px;
  border: 2px solid #fbb938;
}
