.Partners-main-container {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Partners-heading-text-container {
  height: 100px;
  width: 100%;
  font-size: 36px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  opacity: 0.7;
}
.Partners-body-container {
  flex: 1;
  width: 100%;
  display: flex;
  margin-bottom: 5%;
}
.Partners-zerogha-image,
.Partners-angelbroking-image,
.Partners-upstoxs-image,
.Partners-mymoneysage-image {
  display: none;
}

.partners-logo-list {
  display: grid;
  justify-content: center;
  grid-template-columns: 20em 20em 20em 20em;
  grid-gap: 1em 1em;
  height: 100%;
  padding: 2em;
}

@media (max-width: 81.125em) {
  .partners-logo-list {
    display: grid;
    justify-content: center;
    grid-template-columns: 20em 20em 20em;
    grid-gap: 1em 1em;
    height: 100%;
    padding: 2em;
  }
}

@media (max-width: 59.3125em) {
  .partners-logo-list {
    display: grid;
    justify-content: center;
    grid-template-columns: 20em 20em;
    grid-gap: 1em 1em;
    height: 100%;
    padding: 2em;
  }
}

@media (max-width: 39.3125em) {
  .partners-logo-list {
    display: grid;
    justify-content: center;
    grid-template-columns: 20em;
    grid-gap: 2em;
    height: 100%;
    padding: 2em;
  }
}

@media (max-width: 699px) {
  .Partners-heading-text-container {
    font-size: 28px;
  }
  .Partners-main-container {
    height: 100%;
  }
  .Partners-body-container {
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
  }
  .Partners-zerogha-image,
  .Partners-angelbroking-image,
  .Partners-upstoxs-image,
  .Partners-mymoneysage-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .slider {
    display: none;
  }
}
/* @keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-150px * 5));
  }
} */
.slider {
  background: white;
  height: 200px;
  margin: auto;
  overflow: hidden;
  /* width: 1100px; */
  justify-content: center;
  align-items: center;
}
.slider::before,
.slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  animation: scroll 30s linear infinite;
  display: flex;
  width: calc(250px * 14);
  align-items: center;
}
.slider .slide {
  height: 100px;
  width: 350px;
}

@media (min-width: 700px) {
  .slider {
    display: flex;
  }
}
