.calculator_Whole_page {
  height: 100%;
}

.calculatorPage {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: hsl(0, 0%, 100%);
}

.calc-Header {
  flex: 0.25;
  display: flex;
  background-color: #32373c;
}

.calc-Body {
  flex: 3.5;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
}

.calc-Footer {
  flex: 0.4;
  display: flex;
  background-color: #f5f4f4c9;
  border-top: 1px solid #e3ddd8fc;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.5em 0em 1.5em 0em;
  font-size: 0.78em;
  letter-spacing: 0.02em;
  color: #504e4e;
}

.calc-HeaderContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0.7em;
}

.calc-Lable {
  flex: 1;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  color: white;
  font-size: 1em;
  font-weight: 600;
}
.calc-Close {
  flex: 1;
  text-align: right;
  color: white;
  font-size: 2em;
}
#calc-cancelIcon {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
}
#calc-cancelIcon:hover {
  cursor: pointer;
}

.calc-InputContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 2em;
}
.calc-ResultContainer {
  flex: 1;
  display: flex;
}

.calc-Periods {
  padding: 0em 0em 0.4em 0em;
  font-size: 0.9em;
  letter-spacing: 0.02em;
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.calc-Past {
  padding-right: 8em;
}

#calc-RadioGroup {
  flex-direction: row;
}

.calc-ScripSelector {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
  margin: 1em 0em;
  letter-spacing: 0.02em;
}

.calc-Selector {
  width: 100%;
  font-size: 0.85em;
  letter-spacing: 0.02em;
}
#calc-ScripSelectorText {
  font-size: 0.85em;
  letter-spacing: 0.02em;
  height: 0.9em;
}
#calc-MenuItem {
  font-size: 0.85em;
  letter-spacing: 0.02em;
  color: #504e4e;
}
.calc-InvestedLable {
  display: flex;
  padding: 2em 0em 1em 0em;
  font-size: 0.85em;
  letter-spacing: 0.02em;
  color: #504e4e;
}

.calc-Text {
  flex: 1;
  outline: #ffe600;
  width: 100%;
  color: #504e4e;
}

.calc-ScripsLable {
  display: flex;
  padding: 2em 0em 1em 0em;
  color: #504e4e;
}

.calc-InvestedAmount {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.calc-YearSlider {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
  letter-spacing: 0.02em;
}

.calc-YearList {
  display: flex;
  flex-direction: row;
  color: #504e4e;
  justify-content: space-between;
  flex: 1;
  cursor: pointer;
}
.calc-Year {
  padding-right: 1em;
}
.calc-YearLable {
  display: flex;
  padding: 2em 0em 1.2em 0em;
  color: #504e4e;
}

.calc-SlideSelector {
  display: flex;
  padding: 2em 0em 1em 0em;
}

.calc-Slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.calc-Slider:hover {
  opacity: 1;
}

.calc-Slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: radial-gradient(rgb(250, 250, 250), #ffe600, #ffbb00);
  cursor: pointer;
  border-radius: 50%;
}

.calc-ButtonContainer {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 1em;
}

.calc-Button button {
  background-color: #ffe600;
  font-size: 0.9em;
  color: black;
  font-weight: 500;
}

.calc-Button button:hover {
  background-color: #ffe600;
}

.calc-ResultData {
  display: flex;
  flex-direction: column;
  height: 15em;
  width: 100%;
  position: relative;
  left: 20%;
  margin-top: 2em;
  border: 1px solid rgb(252, 245, 245);
  background-color: white;
  -webkit-box-shadow: -11px 13px 21px -12px rgba(194, 192, 192, 1);
  -moz-box-shadow: -11px 13px 21px -12px rgba(194, 192, 192, 1);
  box-shadow: -11px 13px 21px -12px rgba(194, 192, 192, 1);
  border-radius: 1px;
}

.calc-ResultHeader {
  display: flex;
  font-size: 1.1em;
  letter-spacing: 0.02em;
  font-weight: 600;
  padding: 1em 1em 1em 0em;
  border-bottom: 1px solid rgb(234, 235, 235);
}

.calc-ResultBody {
  display: flex;
  flex-direction: row;
}
.calc-ResultLable {
  flex: 1;
  font-size: 1em;
  letter-spacing: 0.02em;
  color: #504e4e;
}
.calc-ResultValue {
  color: #000;
  font-size: 1em;
  letter-spacing: 0.02em;
  text-align: right;
  font-weight: 900;
}

.calc-ResultReturn,
.calc-ReturnValue {
  padding-top: 1em;
}

@media (max-width: 454px) {
  .calc-Past,
  .calc-Future {
    flex: 1;
    padding: 0;
  }
  .calc-Year,
  .calc-LastYear {
    padding-right: 0;
    flex: 1;
    font-size: 0.8em;
  }
}

@media (max-width: 330px) {
  .calc-InputContainer {
    padding: 1em;
  }
}

@media (max-width: 874px) {
  .calculator_Whole_page {
    padding: 0;
    align-items: normal;
    justify-content: normal;
  }
  .calculatorPage {
    width: 100%;
  }
  .calc-Header {
    /* min-height: 46.38px; */
  }
  .calc-Body {
    flex-direction: column;
    /* min-height: 749.36px; */
  }
  .calc-ResultData {
    left: 0em;
    margin: 0em;
  }

  .calc-ResultContainer {
    justify-content: center;
    padding: 1em;
  }
  .calc-Year {
    padding-right: 0;
    flex: 1;
  }
  .calc-Footer {
    /* padding: 1em 1em 1em 1em; */
    /* min-height: 64.44px; */
  }
}

#radio .ff {
  visibility: hidden;
}
