@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,700");

* {
  font-family: "Roboto";
}
.App {
  text-align: center;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-row: auto; */
  width: 100%;
  height: 100%;
}

.Header-bar {
  min-height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.parallax {
  /* The image used */
  background-image: url("./assets/image/AboutUs_BG_1.png");
  /* Full height */
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax2 {
  background-image: url("./assets/image/architecture-black-and-white-buildings-264594.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax3 {
  background-image: url("./assets/image/pexels-photo-1803739.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.main-tabs-container {
  position: relative;
  top: -80px;
  margin-bottom: -80px;
}
