.small-case-main {
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  padding-bottom: 30px;
}
.background-text {
  font-size: 7rem;
  font-weight: 700;
  letter-spacing: 9px;
  color: #fbd2498a;
  z-index: 0;
  overflow: hidden;
}
.smallcase-heading-text-container {
  height: 80px;
  width: 100%;
  font-size: 36px;
  font-weight: 300;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: black;
  opacity: 0.7;
  margin-top: 30px;
}
.smallcase-text-image-container {
  height: 100%;
  width: 80%;
  font-size: 18px;
  color: black;
  opacity: 0.7;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 0;
}
.smallcase-text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: column;
  text-align: left;
}
.text-container1 {
  font-size: 32px;
  color: black;
  font-weight: 300;
  opacity: 0.7;
  line-height: 1.4;
}
.text-container2 {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: -0.1px;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 20px;
}
.smallcase-image-container {
  width: 30%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.brokers-icons-container {
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
}
.broker-icon {
  max-width: 40px;
  height: 40px;
  background: white;
  border-radius: 26px;
  margin-right: 20px;
  padding: 10px;
  -webkit-transition-property: width; /* Safari */
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-property: width;
  transition-duration: 0.5s;
  box-shadow: 1px 1px 5px #040404a1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.broker-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.smallcase-cards {
  min-height: 350px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.smallcase-card-1 {
  max-width: 480px;
  min-height: 300px;
  background-image: url("../image/GTurns_Momentum.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  background-color: white;
  box-shadow: 0 10px 24px 0 rgba(250, 250, 250, 0.2),
    0 8px 16px 0 rgba(33, 33, 33, 0.3);
  transition: transform 0.2s;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: black;
}
.smallcase-card-1:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.smallcase-card-header {
  padding: 10px 0;
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  opacity: 0.7;
}
.smallcase-card-description {
  padding: 20px 0px;
  text-align: left;
  width: 70%;
  font-weight: 400;
  opacity: 0.7;
  line-height: 1.4;
}
.smallcase-card-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.smallcase-bottom-link {
  padding: 30px 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  color: black;
  opacity: 0.6;
}
.arrow-icon-bottom-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b18a14;
  width: 20px;
  -webkit-transition-property: width; /* Safari */
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-property: width;
  transition-duration: 0.5s;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.arrow-text-bottom-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b18a14;
  -webkit-transition-property: width; /* Safari */
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-property: width;
  transition-duration: 0.5s;
  width: 320px;
}
.arrow-text-bottom-link:hover {
  width: 340px;
  cursor: pointer;
  font-weight: 700;
  transform: translate(2%, 0%) matrix(1, 0, 0, 1, 0, 0);
}
.gcore-product-Info {
  width: 90%;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-top: 0.1px solid #eae8e8;
}
.gcore-description {
  letter-spacing: 0.3px;
  line-height: 1.8;
  width: 60%;
  text-align: left;
  color: black;
  opacity: 0.7;
}
@media (max-width: 800px) {
  .smallcase-cards {
    padding: 0px;
    width: 100%;
  }
  .smallcase-heading-text-container {
    font-size: 28px;
  }
  .smallcase-card-1 {
    min-height: 230px;
    width: 90%;
    padding: 20px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .smallcase-text-image-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 0;
    flex-direction: column;
    width: 90%;
  }
  .smallcase-text-container {
    width: 100%;
  }
  .brokers-icons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .smallcase-image-container {
    width: 100%;
    align-items: center;
    padding-top: 80px;
  }
  .background-text {
    font-size: 3.5rem;
  }
  .text-container1 {
    font-size: 24px;
  }
  .gcore-product-Info {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }
  .gcore-description {
    letter-spacing: 0.3px;
    line-height: 1.8;
    width: 100%;
    text-align: justify;
    color: black;
    opacity: 0.7;
    padding: 10px;
  }
  .broker-icon {
    margin-right: 15px;
  }
}
