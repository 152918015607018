.blogsList-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.blogsListHeaderBackground {
  height: 90px;
  background-color: black;
  box-shadow: 0 8px 6px -6px rgb(44, 38, 38);
}
.blogsList-body-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #f7f7f7;
  /* border: 0.5px solid coral; */
}
.blogsList-body-content-container {
  width: 80%;
}
.temp-blog-Data {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.temp-blog-container {
  display: flex;
  flex-direction: column;
}
.temp-blog-header {
  text-align: left;
  font-size: 2em;
  font-weight: 300;
  opacity: 0.7;
  padding: 10px 0px;
}
.temp-blog-description {
  color: gray;
  text-align: left;
  font-size: 1.1em;
  letter-spacing: 0.3px;
  padding: 10px 0px;
  color: gray;
  line-height: 1.5;
}
.blogsList-corosal-3cards-container {
  margin-top: 7%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.blogsList-corosal-container {
  min-height: 350px;
  width: 60%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.blogsList-3cards-container {
  width: 38%;
  display: flex;
}
.blogs-lists-and-blog-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2em 8em;
}
.blogs-lists {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 2.5em;
}
#blog-picture {
  height: 150px;
  width: 100%;
  border-radius: 0.5em;
}
.recent-blog-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  text-align: left;
  background: #f7f4f4;
  padding: 1em;
  border-radius: 1em;
  box-shadow: 3px 3px 8px #dad6d6;
}
.carousel-blogs {
  display: flex;
  flex-direction: row;
}
@media (max-width: 899px) {
  .carousel-blogs {
    display: flex;
    flex-direction: column;
  }
  .blogsList-body-content-container {
    width: 90%;
  }
  .blogs-lists-and-blog-container {
    padding: 1em;
  }
  .blogsList-corosal-3cards-container {
    width: 100%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
  }
  .blogsList-corosal-container {
    width: 100%;
    height: 280px;
  }
  .blogsList-3cards-container {
    width: 100%;
    margin-top: 10%;
    height: 400px;
  }
}
@media (max-width: 1400px) {
  .blogs-lists-and-blog-container {
    padding: 2em;
  }
}
@media (max-width: 1000px) {
  .blogs-lists-and-blog-container {
    padding: 2em;
    flex-direction: column;
  }
  .blogs-lists {
    width: 90%;
    margin: 1em;
    margin-right: 0;
  }
}
