.contactInfo-container {
  width: 100%;
  background-color: rgb(26, 26, 26);
  color: rgb(255, 255, 255);
  padding-bottom: 30px;
  padding-top: 50px;
}

.contactInfo-subContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: rgba(204, 204, 204, 0.8);
  justify-content: center;
  align-items: center;
}

.companySlogan {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10%;
  padding-right: 0%;
}

.slogan-description {
  font-size: 15px;
  font-weight: 400;
  text-align: justify;
  padding-bottom: 40px;
  padding-right: 10px;
  line-height: 2em;
  letter-spacing: 0.3px;
}

.socialMedia-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding-top: 5%;
}

.icons-holder {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.twitter-icon {
  color: rgba(204, 204, 204, 0.8);
  font-size: 25px;
  margin: 7px;
}

.facebook-icon,
.linkedin-icon {
  color: rgba(204, 204, 204, 0.8);
  font-size: 25px;
  margin: 7px;
}

.socialMediaHeading {
  font-size: 15px;
  color: rgb(241, 241, 241);
  letter-spacing: 1px;
}

.contact-info-heading {
  font-size: 15px;
  color: rgb(241, 241, 241);
  padding-top: 5%;
}

.address-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.office-address {
  font-size: calc(7px + 1vmin);
  text-align: justify;
  padding-right: 10px;
}

.address-icon-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  padding-right: 7px;
}

.formContainers {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 10%;
  height: 100%;
}

.emailSubscription {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}

.dropMessage {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 10%;
}

.input-container-one {
  flex-direction: row;
  display: flex;
  padding-top: 4%;
}

::placeholder {
  font-weight: 400;
  color: rgb(200, 197, 197);
  font-size: 13px;
}

.contactInfo-anchor-container {
  width: 85%;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  padding-top: 20%;
}

.contactInfo-anchor {
  font-size: 12px;
  color: rgba(175, 174, 174, 0.8);
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  font-weight: 500;
  border-right: 0.7px solid rgba(156, 152, 152, 0.8);
}

a:nth-last-of-type(1) {
  border-right: none;
}

.contactInfo-gturns-logo {
  width: 25%;
  align-items: flex-start;
  justify-content: left;
  display: flex;
  margin-bottom: 10%;
}

@media (max-width: 599px) {
  .contactInfo-companySlogan-container {
    height: 100%;
    width: 90%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .formContainers {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 10%;
    height: 100%;
  }

  .emailSubscription {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 6%;
  }

  .companySlogan {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding-left: 3;
    padding-right: 3%;
  }
}

@media (min-width: 861px) {
  .contactInfo-companySlogan-container {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex: 1;
  }
}

@media (max-width: 860px) {
  .contactInfo-companySlogan-container {
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
