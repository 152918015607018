.howItWorks-heading-container {
  padding-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.howItWorks-heading-text-image-container {
  width: 90%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  background: transparent;
  color: white;
  margin-bottom: 7%;
  padding-top: 6%;
}

.howItWorks-header-heading-container {
  font-size: 45px;
  font-weight: 300;
  margin-bottom: 10%;
}

.howItWorks-heading-text-container {
  width: 45%;
  text-align: initial;
  justify-content: center;
  letter-spacing: 0.5px;
  padding-top: 0;
  color: white;
}

.howItWorks-header-description-container {
  line-height: 2em;
  font-size: 15px;
  color: #d7dce2d9;
  text-align: justify;
  letter-spacing: 0.4px;
}

.howItWorksVideo {
  height: 30%;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.howItWorksVideoIframe {
  height: 100%;
  width: 100%;
}

@media (max-width: 599px) {
  .howItWorks-heading-text-image-container {
    flex-direction: column;
  }
  .howItWorks-header-heading-container {
    font-size: 35px;
    font-weight: 300;
    margin-top: 10%;
    margin-bottom: 10%;
    text-align: left;
  }
  .howItWorks-header-description-container {
    text-align: left;
  }
  .howItWorks-heading-text-container {
    width: 95%;
    text-align: center;
  }
  .howItWorks-header-description-container {
    line-height: 2em;
    font-size: 14px;
  }
  .howItWorksVideo {
    width: 100%;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .howItWorksVideoIframe {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 850px) and (min-width: 600px) {
  .howItWorks-heading-text-image-container {
    flex-direction: column;
  }
  .howItWorks-heading-text-container {
    width: 95%;
    font-size: 25px;
  }
  .howItWorksVideo {
    margin-top: 10%;
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .howItWorksVideoIframe {
    height: 520px;
    width: 520px;
  }
}

@media (min-width: 1800px) {
  .howItWorks-heading-text-image-container {
    flex-direction: row;
  }
  .howItWorks-heading-text-container {
    width: 45%;
  }
  .howItWorks-header-description-container {
    line-height: 2em;
    font-size: 18px;
  }
  .howItWorksVideo {
    height: 20%;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .howItWorks-header-heading-container {
    font-size: 45px;
    font-weight: lighter;
  }
  .howItWorksVideoIframe {
    height: 500px;
  }
}

.TransparentBlackLayer dialog {
  max-width: 800px;
}
