.planing-component-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.planing-component-heading-container {
  display: flex;
  flex: 1;
  margin: 8%;
  margin-top: 20%;
  margin-bottom: 20%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.planing-component-header-text-container {
  font-size: 45px;
  font-weight: 300;
  color: white;
}

.planing-component-inputYear {
  text-align: right;
  width: 30px;
  border: none;
  padding: 4px 0px;
  margin: 0px 4px;
  font-size: 14px;
  color: rgb(102, 102, 102);
}
.planing-component-description-text-container {
  font-weight: normal;
  margin-top: 2%;
  line-height: 1.7em;
  letter-spacing: 0.3px;
  font-size: 15px;
  color: #d7dce2d9;
}
.planing-component-plan-description-section {
  width: 65%;
  height: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: white;
  text-align: justify;
  position: relative;
  top: 100px;
  border-radius: 3px;
  flex-direction: row;
  display: flex;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.11),
    -2px -1px 1px -7px rgba(0, 0, 0, 0.11), 0px -1px 1px -2px rgba(0, 0, 0, 0);
}
.planing-component-plan-image-section {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.planing-component-plan-text-section {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.planing-component-plan-text-heading-section {
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.planing-component-plan-text-description-section {
  font-size: 14px;
  font-weight: 400;
  margin-top: 2%;
  color: rgb(110, 109, 109);
  line-height: 2em;
  text-align: left;
  text-align: left;
}
.planing-components-body-container {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.planning-components-body-content-containers {
  width: 90%;
  height: 100%;
  /* border: 0.5px solid blue; */
  margin-top: 15%;
  margin-bottom: 20%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.planning-components-body-period-types-containers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* border: 0.5px solid yellow; */
}
.planning-components-body-period-name-containers {
  width: 100%;
  font-weight: 300;
  font-size: 35px;
}
.planning-components-body-period-selectors-containers {
  width: 60%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 5%;
  /* border: 0.5px solid blue; */
}
.period-selectors-onetime,
.period-selectors-monthly {
  display: flex;
  flex-direction: row;
  width: 40%;
  /* border: 0.5px solid palevioletred; */
  height: 100%;
  justify-content: center;
}
.period-selectors-onetime-radiobutton {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 0.5px solid peru; */
}
.period-selectors-onetime-text {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: 400;
  cursor: pointer;
  /* border: 0.5px solid peru; */
}
.planning-components-body-invested-excepted-containers {
  width: 70%;
  margin-top: 10%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.11),
    -2px -1px 1px -7px rgba(0, 0, 0, 0.11), 0px -1px 1px -2px rgba(0, 0, 0, 0);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  padding: 3%;
}
.planning-components-investment-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  /* border: 0.5px solid blueviolet; */
  align-items: center;
}
.planning-components-Excepted-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  /* border: 0.5px solid rgb(23, 4, 41); */
  align-items: flex-start;
}
.excepted-returns-contents-container {
  width: 90%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 0.5px solid #ffd118;
}
.expected-yearscontainers {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2%;
}
.expected-noof-year-containers {
  color: rgb(110, 109, 109);
  font-size: 14px;
  background-color: black;
  width: 30px;
  border-radius: 10px;
  cursor: pointer;
}
.planning-components-body-invested-projectd-container {
  margin-top: 6%;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.invested-value-container,
.expected-value-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3%;
  padding-top: 3%;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.11),
    2px 2px 7px 0px rgba(0, 0, 0, 0.11), 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.expected-value-container {
  outline: 1px solid black;
}

.invested-value-header {
  font-size: 16px;
  font-weight: 500;
  color: rgb(110, 109, 109);
}
.invested-value-text {
  margin-top: 5%;
  font-size: 20px;
}
.section-devider {
  height: 150px;
  border-left: 1px solid rgba(110, 109, 109, 0.4);
  width: 10px;
  margin-left: 2%;
  margin-top: 4%;
  margin-right: 2%;
}
.planning-components-investment-amount-container {
  width: 100%;
  margin-top: 15%;
  font-size: 45px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* border: 0.5px solid red; */
}
.planning-amount {
  opacity: 0.87;
}
.planning-components-range-slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}
.amount-slider {
  -webkit-appearance: none;
  width: 80%;
  height: 5px;
  border-radius: 5px;
  background: #ffd118;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.amount-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.986));
  cursor: pointer;
}

.amount-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  cursor: pointer;
}
.slider-increase-decrase-buttons {
  width: 80%;
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.planning-components-body-plan-info-containers {
  width: 80%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  /* border: 0.5px solid gold; */
}
.plan-info-heading-text-container {
  width: 100%;
  text-align: left;
  font-size: 21px;
  font-weight: 400;
}
.plan-info-description-text-container {
  font-size: 14px;
  font-weight: 400;
  margin-top: 2%;
  color: rgb(110, 109, 109);
  line-height: 1.7rem;
  text-align: left;
}
.planning-components-body-returns-comparision-containers {
  width: 80%;
  margin-top: 10%;
  display: flex;
  /* border: 0.5px solid rgb(0, 255, 76); */
  flex-direction: column;
}
.returns-comparing-charts-heading {
  width: 100%;
  text-align: left;
  font-size: 21px;
  font-weight: 400;
}
.returns-comparing-charts {
  margin-top: 3%;
  height: 500px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.11),
    -2px -1px 1px -7px rgba(0, 0, 0, 0.11), 0px -1px 1px -2px rgba(0, 0, 0, 0);
  border-radius: 3px;
  background-color: white;
}
.radiocontainer {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.radiocontainer input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: #5a5f73;
  border-radius: 50%;
}
.radiocontainer input:checked ~ .checkmark {
  background-color: #5a5f73;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radiocontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radiocontainer .checkmark:after {
  top: 7px;
  left: 7px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ffd118;
}
.radiocontainer input:checked ~ .checkmark:after {
  display: block;
}

#slider1 {
  position: relative;
  right: 8%;
}
#slider2 {
  padding-left: 10%;
}

#slider3 {
  padding-left: 28%;
}

#slider4 {
  padding-left: 42%;
}

#sliderContainer5 {
  justify-content: flex-end;
}

@media (max-width: 700px) {
  #slider1 {
    left: -10%;
  }
  #slider2 {
    padding-left: 5%;
  }

  #slider3 {
    padding-left: 20%;
  }

  #slider4 {
    padding-left: 32%;
  }
}

@media (max-width: 474px) {
  #slider3 {
    padding-left: 10%;
  }

  #slider4 {
    padding-left: 21%;
  }
}

@media (max-width: 320px) {
  #slider2,
  #slider3,
  #slider4,
  #slider5 {
    padding-left: 0;
    position: none;
    left: 0;
  }
}

@media (max-width: 899px) {
  .planing-component-plan-description-section {
    width: 90%;
    height: 100%;
    flex-direction: column;
    justify-content: justify;
    align-items: center;
    padding: 1%;
    top: 150px;
  }
  .planing-component-plan-text-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .planing-component-plan-text-heading-section,
  .planing-component-plan-text-description-section {
    text-align: left;
    padding-top: 3%;
  }
  .planning-report-image {
    width: 100%;
  }
  .planning-components-body-content-containers {
    margin-top: 200px;
    margin-bottom: 55%;
  }
  .planning-components-body-period-selectors-containers {
    width: 100%;
  }
  .period-selectors-onetime,
  .period-selectors-monthly {
    width: 60%;
  }
  .planning-components-body-invested-excepted-containers {
    flex-direction: column;
    justify-content: space-between;
    width: 85%;
    padding: 4%;
  }
  .planning-components-investment-container {
    width: 100%;
  }
  .planning-components-Excepted-container {
    width: 100%;
    align-items: center;
  }
  .planning-components-investment-amount-container {
    width: 100%;
    margin-top: 8%;
  }
  .section-devider {
    height: 1px;
    border-top: 1px solid rgba(110, 109, 109, 0.4);
    width: 100%;
    margin-bottom: 10px;
  }
  .planning-components-body-plan-info-containers {
    width: 90%;
    /* border: 0.5px solid gold; */
  }
  .planning-components-body-returns-comparision-containers {
    width: 90%;
  }
  .excepted-returns-contents-container {
    margin-top: 6%;
  }
}
@media (max-width: 599px) {
  .planning-components-body-invested-projectd-container {
    flex-direction: column;
  }
  .invested-value-container,
  .expected-value-container {
    width: 85%;
  }
  .expected-value-container {
    outline: 1px solid black;
  }
}
