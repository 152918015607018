.blog-social-media-container {
  width: 10%;
  margin: 16% 0 5% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 230px;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  
  /* border: 1px solid red; */
}

.blog-social-media-linkedIn {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  font-size: calc(20px + 1vmin);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #137db7;
  transition: all 0.2s ease-in-out;
}
.blog-social-media-twitter {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  font-size: calc(20px + 1vmin);
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #2ca9df;
  transition: all 0.2s ease-in-out;
}
.blog-social-media-facebook {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  font-size: calc(20px + 1vmin);
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #4e6fa7;
  transition: all 0.2s ease-in-out;
}
.blog-social-media-whatsapp {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  font-size: calc(20px + 1vmin);
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #2fbd39;
  transition: all 0.2s ease-in-out;
}
.blog-social-media-whatsapp:hover,
.blog-social-media-facebook:hover,
.blog-social-media-linkedIn:hover,
.blog-social-media-twitter:hover {
  cursor: pointer;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transform: scale(1.3);
  z-index: 1;
}
@media (max-width: 599px) {
  .blog-social-media-linkedIn,
  .blog-social-media-whatsapp,
  .blog-social-media-facebook,
  .blog-social-media-twitter {
    height: 25px;
    width: 25px;
    font-size: calc(5px + 1vmin);
  }
  .blog-social-media-container {
    height: 160px;
    margin-top: 50%;
  }
}
